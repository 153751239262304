<template>
    <div>Dashboard Page is working</div>
</template>

<script>
import CurrentUserService from '../service/CurrentUserService';

export default {
    data() {
        return {
            userIsACX: false,
        };
    },

    created() {
        this.userService = new CurrentUserService();
    },
    mounted() {
        this.userIsACX = this.userService.marketplaceUser.userRole.roleKey.startsWith('acx:');
        console.log('userIsACX', this.userIsACX);
    },
};
</script>

<style scoped></style>
