<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-lg-3">
                <h5>Bills</h5>
            </div>
        </div>
        <div class="p-grid" v-if="emptyBill">
            <div class="p-col-12 p-md-12">
                <div class="card">
                    <h5 class="muted-text p-text-center">No Bills To Display</h5>
                </div>
            </div>
        </div>
        <div class="p-grid" v-else>
            <div class="p-col-12 p-md-4 p-lg-4">
                <div v-if="bills == null" class="workingTitle">
                    <div class="card">
                        <h5 class="muted-text">Loading Bill</h5>
                        <div class="custom-skeleton p-p-4">
                            <ul class="p-m-0 p-p-0">
                                <li class="p-mb-3">
                                    <div class="p-d-flex">
                                        <Skeleton shape="circle" size="4rem" class="p-mr-2"></Skeleton>
                                        <div style="flex: 1">
                                            <Skeleton width="100%" class="p-mb-2"></Skeleton>
                                            <Skeleton width="75%"></Skeleton>
                                        </div>
                                    </div>
                                </li>
                                <li class="p-mb-3">
                                    <div class="p-d-flex">
                                        <Skeleton shape="circle" size="4rem" class="p-mr-2"></Skeleton>
                                        <div style="flex: 1">
                                            <Skeleton width="100%" class="p-mb-2"></Skeleton>
                                            <Skeleton width="75%"></Skeleton>
                                        </div>
                                    </div>
                                </li>
                                <li class="p-mb-3">
                                    <div class="p-d-flex">
                                        <Skeleton shape="circle" size="4rem" class="p-mr-2"></Skeleton>
                                        <div style="flex: 1">
                                            <Skeleton width="100%" class="p-mb-2"></Skeleton>
                                            <Skeleton width="75%"></Skeleton>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="p-d-flex">
                                        <Skeleton shape="circle" size="4rem" class="p-mr-2"></Skeleton>
                                        <div style="flex: 1">
                                            <Skeleton width="100%" class="p-mb-2"></Skeleton>
                                            <Skeleton width="75%"></Skeleton>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="card p-fluid card-height">
                        <Listbox v-model="billData" :options="bills">
                            <template #option="slotprops">
                                <div
                                    class="p-d-flex p-py-3"
                                    @mouseover="showArrow = slotprops.option.vendorBillNumber"
                                    @mouseout="showArrow = null"
                                >
                                    <div class="p-d-flex p-mr-2 p-ai-center">
                                        <Avatar
                                            class="p-mr-2 p-ml-3"
                                            size="medium"
                                            :class="[
                                                slotprops.option.paymentStatus == 'UNPAID' && 'billUnpaid',
                                                slotprops.option.paymentStatus == 'PAID' && 'billPaid',
                                            ]"
                                            shape="circle"
                                        ></Avatar>
                                        <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                            <div>
                                                <b>#{{ slotprops.option.vendorBillNumber }}</b>
                                            </div>
                                            <small class="muted-text">{{ formatCurrency(slotprops.option.totalAmount) }}</small>
                                        </div>
                                    </div>
                                    <div class="p-mr-2" :class="{ 'p-ml-auto p-mr-2': !isRTL, 'p-mr-auto p-ml-2': isRTL }">
                                        <small class="muted-text">Member Invoice Status:</small><br />
                                        <small>{{
                                            slotprops.option.linkedInvoiceStatus == null
                                                ? 'UNKNOWN'
                                                : slotprops.option.linkedInvoiceStatus
                                        }}</small>
                                    </div>
                                    <div class="p-mr-2 arrowBlock">
                                        <div id="triangle-right" v-show="showArrow == slotprops.option.vendorBillNumber"></div>
                                    </div>
                                </div>
                            </template>
                        </Listbox>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-md-8 p-lg-8">
                <div v-if="bills == null" class="workingTitle">
                    <div class="card">
                        <div class="custom-skeleton p-p-4">
                            <div class="p-d-flex p-jc-between p-mt-3">
                                <Skeleton width="10rem" height="2rem"></Skeleton>
                                <Skeleton width="10rem" height="2rem"></Skeleton>
                            </div>
                            <div class="p-d-flex p-mb-3">
                                <div>
                                    <Skeleton width="10rem" height="0.5rem" class="p-mb-2"></Skeleton>
                                    <Skeleton width="5rem" height="1rem" class="p-mb-2"></Skeleton>
                                    <Skeleton width="10rem" height="1rem" class="p-mb-2"></Skeleton>
                                    <Skeleton height="1rem"></Skeleton>
                                </div>
                            </div>
                            <Skeleton class="p-mb-2" width="80%"></Skeleton>
                            <Skeleton width="70%" height="150px"></Skeleton>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="card p-fluid">
                        <div class="p-d-flex p-jc-between">
                            <div class="p-mr-2">
                                <h5 class="muted-text">Bill #{{ billData.vendorBillNumber }}</h5>
                            </div>
                            <div class="p-ml-auto">
                                <h5 class="p-mt-1 p-ml-5 muted-text">Amount: {{ formatCurrency(billData.totalAmount) }}</h5>
                            </div>
                        </div>
                        <div class="p-d-flex p-flex-column">
                            <div class="p-mb-2 p-d-flex p-flex-column">
                                <div class="p-mb-2">
                                    <div class="p-d-flex p-jc-center">
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span class="muted-text">Bill Submitted date</span>
                                        </div>
                                        <div class="p-mr-2">
                                            <span class="muted-text">:</span>
                                        </div>
                                        <div class="p-mr-2 detailsTransactionDate muted-text">
                                            {{ formatDate(billData.transactionDate) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-mb-2">
                                    <div class="p-d-flex p-jc-center">
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span class="muted-text">Member Invoice Status</span>
                                        </div>
                                        <div class="p-mr-2">
                                            <span class="muted-text">:</span>
                                        </div>
                                        <div class="p-mr-2 detailsTransactionDate muted-text">
                                            {{ billData.linkedInvoiceStatus == null ? 'UNKNOWN' : billData.linkedInvoiceStatus }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-mb-2">
                                    <div class="p-d-flex p-jc-center">
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span class="muted-text">ArenaCX Status</span>
                                        </div>
                                        <div class="p-mr-2">
                                            <span class="muted-text">:</span>
                                        </div>
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span
                                                class="billStatus"
                                                :class="[
                                                    billData.paymentStatus == 'UNPAID' && 'billUnpaid',
                                                    billData.paymentStatus == 'PAID' && 'billPaid',
                                                ]"
                                            >
                                                {{ billData.paymentStatus }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-mb-2 p-d-flex p-flex-column">
                            <div class="p-mb-2">
                                <span class="muted-text">Transactions :</span>
                                <hr class="rounded" />
                            </div>
                            <div class="p-mb-2">
                                <ul class="p-m-0 p-p-0 p-d-flex p-flex-column">
                                    <li
                                        class="p-mb-2 p-d-flex detailsBillLine"
                                        v-for="item in billData.lines"
                                        :key="item.sourceLineId"
                                    >
                                        <div
                                            class="p-mr-2 p-text-right muted-text lineNumber"
                                            style="flex: 2; display: inline-block"
                                        >
                                            Line #{{ item.sourceLineId }}:
                                        </div>

                                        <div
                                            class="p-mr-6 p-text-right muted-text lineAmount"
                                            style="flex: 1; display: inline-block"
                                        >
                                            {{ formatCurrency(item.amount) }}
                                        </div>

                                        <div class="p-mr-2 p-ml-2 p-text-left muted-text" style="flex: 4; display: inline-block">
                                            {{ item.description }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MarketplaceApiService from '../service/MarketplaceApiService';

export default {
    data() {
        return {
            accountId: '',
            bills: null,
            accountDetails: null,
            accountName: '{working...}',
            isLoading: true,
            expandedRows: [],
            billData: {},
            showArrow: null,
            emptyBill: false,
        };
    },

    created() {
        //console.log('CREATED fired');
        this.apiService = new MarketplaceApiService();
        this.refreshBillData();
    },

    updated() {},

    watch: {
        $route() {
            this.refreshBillData();
        },
        bills: {
            handler(newValue) {
                if (newValue != null && newValue.length == 0) {
                    this.emptyBill = true;
                } else {
                    this.emptyBill = false;
                }
            },
            deep: true,
        },
    },

    apiService: null,

    mounted() {
        //console.log('MOUNTED fired');
        this.refreshBillData();
    },

    methods: {
        refreshBillData() {
            this.bills = null;
            this.isLoading = true;

            this.apiService.getPartnerBills().then((data) => {
                this.bills = data;
                this.billData = data[0];
            });

            this.apiService.getAccountDetails().then((data) => {
                this.accountDetails = data;
                this.accountName = this.accountDetails.name;
            });

            this.isLoading = false;
        },

        formattedDate(dateString) {
            var dt = new Date(dateString);
            dt.setDate(dt.getDate() - 350);
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return dt.toLocaleDateString('en-US', options);
        },

        writeBills() {
            console.log(this.bills);
        },
        formatCurrency(value) {
            if (value) return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        formatDate(value) {
            if (value) {
                var dt = new Date(value);
                return dt.toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            }
        },
        displayBillDetails(data) {
            this.billData = data;
        },
    },
    computed: {
        isRTL() {
            return this.$appState.RTL;
        },
    },
};
</script>

<style lang="scss" scoped>
.card-height {
    max-height: 570px;
    height: auto;
    overflow-y: auto;
    padding: 0;
    ul > li {
        padding: 0 1px 0 1px;
    }
}

#triangle-right {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 15px solid #a0d2fa;
    border-bottom: 20px solid transparent;
}

.arrowBlock {
    width: 20px;
    height: 20px;
}

.rounded {
    border-top: 3px solid #bbb;
    border-radius: 3px;
}

li.hoverclass:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.workingTitle {
    color: #0f5b60;
}

.detailsBill {
    cursor: default;
}
.billHeader {
    display: inline-block;
}
.billNumber {
    display: inline-block;
    width: 250px;
    border-bottom: 1px solid #999999;
}

.detailsTransactionDate {
    display: inline-block;
    width: 250px;
}

.billTotal {
    display: inline-block;
    width: 165px;
    border-bottom: 1px solid #999999;
}

.summaryDetails {
    padding-left: 17px;
    font-size: 90%;
}

.detailsBill {
    padding: 12px;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.detailsBillLine {
    margin: 5px;
    margin-left: 20px;
    font-size: 85%;
}

.detailsBillLine div {
    color: #444444;
    margin-right: 7px;
}

.payLink {
    width: 250px;
}

.payLink a {
    color: green;
    font-weight: bold;
    text-align: right;
}
.payLink a:hover {
    color: blue;
}

.lineNumber {
    width: 60px;
    display: inline-block;
}

.lineAmount {
    width: 100px;
    display: inline-block;
}

.billStatus {
    font-weight: bold;
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
}
.billStatusOkay {
    color: darkgreen;
    background-color: palegreen;
}
.billUnpaid {
    color: #ffffff;
    background-color: #fd705e;
}
.billPaid {
    color: #ffffff;
    background-color: #57be92;
}
.billStatusOkayColor {
    color: #ffffff;
    background-color: darkgreen;
}

.ml-100 {
    margin-right: 20px;
}

@media screen and (max-width: 768px) {
    .card-height {
        max-height: 200px;
        height: auto;
        overflow-y: auto;
        padding: 0;
        ul > li {
            padding: 0 5px 0 5px;
        }
    }

    #triangle-right {
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-left: 10px solid #a0d2fa;
        border-bottom: 15px solid transparent;
    }
}
</style>
