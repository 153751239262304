import getAxios from './common/header';

export default class PartnerSelectorService {
    async getPartnerSelector() {
        try {
            var response = await getAxios().get(`${process.env.VUE_APP_BASE_URL}partner-selector`);
            console.log(response.status);
            return await response;
        } catch (err) {
            console.log(err.response);
            return err.response;
        }
    }

    async postPartnerSelector(payload) {
        var partnerSelectorData = await getAxios()
            .post(`${process.env.VUE_APP_BASE_URL}partner-selector`, payload)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.log('--ERROR TIME!--');
                console.log(err);
                return err.response;
            });
        return partnerSelectorData;
    }
}
