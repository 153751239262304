<template>
    <div class="p-d-flex p-jc-between">
        <div>
            <p class="title">Partner selector</p>
            <p class="sub-title">Enter your filter conditions and shortlist the partner you would like to work with</p>
        </div>

        <div>
            <Button label="Create New" class="mr-2 inline-block create-new-btn" @click="openCreateNewDialog" />
        </div>
    </div>

    <div class="table-wrap">
        <DataTable
            ref="dt"
            :value="partnerInfos"
            :paginator="true"
            :rows="5"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            @page="onPage($event)"
        >
            <Column :exportable="false" style="min-width: 1rem">
                <!-- <template #body="slotProps">
                    <Button
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-warning"
                        @click="editPartnerRow(slotProps.data, slotProps.index)"
                    />
                    <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-warning"
                        @click="deletePartnerRow(slotProps.data, slotProps.index)"
                    />
                </template> -->
            </Column>
            <Column field="name" header="Name" style="min-width: 9rem"></Column>
            <Column field="createdDate" header="Created date" style="min-width: 9rem"></Column>
            <Column field="createdBy" header="Created by" style="min-width: 9rem"></Column>
            <Column field="shortlistedPartner" header="Shortlisted partner" style="min-width: 9rem"></Column>
        </DataTable>

        <Dialog
            v-model:visible="createNewDialog"
            :style="{ width: '600px' }"
            header="Create new partner selector"
            :modal="true"
            class="p-fluid"
        >
            <div class="field">
                <label for="name">Name</label>
                <InputText id="name" v-model="partnerInfo.name" required="true" placeholder="Enter name" />
            </div>

            <div class="field">
                <label for="textarea">Description</label>
                <Textarea id="textarea" v-model="partnerInfo.description" rows="5" placeholder="Enter description" />
            </div>

            <template #footer>
                <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="hideDialog" />
                <Button v-if="editIndex === null" label="Save" class="p-button p-component button" @click="onPartnerInfoSave" />
                <Button v-else label="Update" class="p-button p-component button" @click="onPartnerInfoSave" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteParterDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 1.75rem; margin-right: 0.5rem" />
                <span v-if="partnerInfo"
                    >Are you sure you want to delete <b>{{ partnerInfo.name }}</b
                    >?</span
                >
            </div>

            <template #footer>
                <Button label="No" class="p-button p-component p-button-outlined button" @click="deleteParterDialog = false" />
                <Button label="Yes" class="p-button p-component button" @click="onConfirmDeletePartner" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { useVuelidate } from '@vuelidate/core';
import PartnerSelectorService from '../service/PartnerSelectorService';

export default {
    name: 'PartnerSelectorInfo',
    data() {
        return {
            partnerInfos: [],
            createNewDialog: false,
            deleteParterDialog: false,
            partnerInfo: {},
            submitted: false,
            editIndex: null,
            deletePartnerInfoIndex: null,
            intializeOnce: true,
            paginationData: { columnOffset: 0 },
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
    },
    methods: {
        onPage(event) {
            this.paginationData = {
                page: event?.page, // current page
                pageCount: event?.pageCount, // total page count
                rows: event?.rows, // Number of rows to display in new page
                columnOffset: event?.page * event?.rows,
            };
        },
        openCreateNewDialog() {
            this.partnerInfo = {};
            this.submitted = false;
            this.createNewDialog = true;
        },
        hideDialog() {
            this.createNewDialog = false;
            this.submitted = false;
            this.editIndex = null;
        },
        editPartnerRow(partnerInfo, index) {
            this.partnerInfo = { ...partnerInfo };
            this.editIndex = this.paginationData?.columnOffset + index;
            this.createNewDialog = true;
        },
        deletePartnerRow(partnerInfo, index) {
            this.partnerInfo = { ...partnerInfo };
            this.deletePartnerInfoIndex = this.paginationData?.columnOffset + index;
            this.deleteParterDialog = true;
        },
        onConfirmDeletePartner() {
            this.partnerInfos.splice(this.deletePartnerInfoIndex, 1);
            this.deleteParterDialog = false;
            this.partnerInfo = {};
            this.validationCall();
        },
        onPartnerInfoSave() {
            if (!this.partnerInfo.name || this.partnerInfo.name.trim() === '') {
                return this.$toast.add({
                    severity: 'error',
                    summary: 'Error while creating data',
                    detail: 'Please enter name',
                    life: 3000,
                });
            }
            if (this.partnerInfo.name) this.partnerInfo.name = this.partnerInfo.name.trim();
            if (this.partnerInfo?.description) this.partnerInfo.description = this.partnerInfo.description.trim();

            this.hideDialog();
            this.validationCall();
            this.postPartnerSelector({
                name: this.partnerInfo.name,
                description: this.partnerInfo.description,
            });
        },
        validationCall() {
            this.$emit('submit', this.v$.$silentErrors.length, this.partnerInfos);
            this.v$.$touch();
        },
        postPartnerSelector(payload) {
            this.partnerSelectorService.postPartnerSelector(payload).then((res) => {
                if (!res.id) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error while creating data',
                        detail: res.data,
                        life: 3000,
                    });
                }
            });
        },
        async getPartnerSelector() {
            this.rootDirtyInd = false;
            var response = await this.partnerSelectorService.getPartnerSelector();
            var data = response.data;
            data &&
                data.forEach((d) => {
                    this.partnerInfos.push({
                        name: d.name,
                        createdDate: moment(d.created_date).format('MM/DD/YYYY'),
                        createdBy: d.created_by,
                        shortlistedPartner: d.shortlisted_partner,
                        id: d.id,
                    });
                });
        },
    },
    mounted() {
        this.partnerSelectorService = new PartnerSelectorService();
        this.getPartnerSelector();
    },
};
</script>

<style scoped lang="scss">
.title {
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
}

.sub-title {
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: 1rem;
}

::v-deep(.p-inputtext) {
    margin: 0.75rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.create-new-btn {
    margin-top: 1.6rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}

::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}

::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}

.field {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0.75rem;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: start;
}

.table-wrap {
    margin: 4rem 0;
}

.toolbar-wrap {
    width: 100%;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    margin-top: 2rem;
}
</style>
