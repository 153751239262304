<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.average_agents_per_qa_admin }}</p>

            <InputText
                class="input-text"
                v-model="v$.avgQAAdmin.$model"
                @input="changeHandler"
                placeholder="Enter average number of agents per QA admin"
            />
            <p v-for="error of v$.avgQAAdmin.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.qa_rubric }}</p>
            <Dropdown
                v-model="v$.QARubic.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select standard QA rubric"
            />
            <p v-for="error of v$.QARubic.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.reporting_arenacx }}</p>
            <Dropdown
                v-model="v$.APIsAvailable.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select APIs available in your systems that ArenaCX can access"
            />
            <p v-for="error of v$.APIsAvailable.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.reporting }}</p>
            <Dropdown
                v-model="v$.reportingDashboard.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select reporting dashboards your clients can view"
            />
            <p v-for="error of v$.reportingDashboard.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.qa_remote_monitoring }}</p>
            <Dropdown
                v-model="v$.remoteMonitor.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select remote monitoring capabilities"
            />
            <p v-for="error of v$.remoteMonitor.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.qa_remote_monitoring_clients }}</p>
            <Dropdown
                v-model="v$.clientDial.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select clients dial in at any time"
            />
            <p v-for="error of v$.clientDial.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.qa_call_recording }}</p>
            <Dropdown
                v-model="v$.callRecord.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select digitally record all calls"
            />
            <p v-for="error of v$.callRecord.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ReportingAndQALabel.qa_call_recording_percent }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.callRecordPCT.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of record calls"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.callRecordPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.reporting_customized }}</p>
            <Textarea
                class="input-text"
                v-model="v$.adhocReporting.$model"
                @input="changeHandler"
                placeholder="Enter your ad hoc reporting capabilities"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="adhocReportingID"
                :logoUrl="adhocReportingURL"
            />
            <p v-for="error of v$.adhocReporting.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.qa_agent_feedback }}</p>
            <Textarea
                class="input-text"
                v-model="v$.agentFeedBack.$model"
                @input="changeHandler"
                placeholder="Enter agent feedback process"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="agentFeedBackID"
                :logoUrl="agentFeedBackURL"
            />
            <p v-for="error of v$.agentFeedBack.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ReportingAndQALabel.qa_process }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalQAProcess.$model"
                @input="changeHandler"
                placeholder="Enter your internal QA processes"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="internalQAProcessID"
                :logoUrl="internalQAProcessURL"
            />
            <p v-for="error of v$.internalQAProcess.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ReportingAndQALabel.qa_call_monitoring_process }}
            </p>
            <Textarea
                class="input-text"
                v-model="v$.callMonitorProcess.$model"
                @input="changeHandler"
                placeholder="Enter call monitoring and expected and realized benefits of the interaction monitoring process"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="callMonitorProcessID"
                :logoUrl="callMonitorProcessURL"
            />
            <p v-for="error of v$.callMonitorProcess.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ReportingAndQALabel.quality_assurance_rubric }}
                <Tooltip description="The file can be in Word or PDF format." />
            </p>

            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="false"
                :id="partnerId"
                :fieldId="QARubicID"
                :logoUrl="QARubicURL"
            />
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';
import Tooltip from '../Tooltip.vue';

const positiveDecimal = (value) => {
    if (value && (value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};

export default {
    components: {
        FileUpload,
        Tooltip,
    },
    props: ['saveInd', 'ReportingAndQAValues'],
    data() {
        return {
            ReportingAndQALabel: answerBankLabel.ReportingAndQA,
            reportingDashboard: null,
            adhocReporting: null,
            adhocReportingURL: null,
            adhocReportingID: null,
            APIsAvailable: null,
            internalQAProcess: null,
            internalQAProcessURL: null,
            internalQAProcessID: null,
            QARubic: null,
            remoteMonitor: null,
            clientDial: null,
            callRecord: null,
            callRecordPCT: null,
            callMonitorProcess: null,
            callMonitorProcessURL: null,
            callMonitorProcessID: null,
            agentFeedBack: null,
            agentFeedBackURL: null,
            agentFeedBackID: null,
            avgQAAdmin: null,
            QARubicID: null,
            QARubicURL: null,
            partnerId: null,
            yesNoOptions: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            reportingDashboard: { minLength: minLength(1) },
            adhocReporting: { $autoDirty: true },
            APIsAvailable: { minLength: minLength(1) },
            internalQAProcess: { $autoDirty: true },
            QARubic: { minLength: minLength(1) },
            remoteMonitor: { minLength: minLength(1) },
            clientDial: { minLength: minLength(1) },
            callRecord: { minLength: minLength(1) },
            callRecordPCT: { positiveDecimal },
            callMonitorProcess: { $autoDirty: true },
            agentFeedBack: { $autoDirty: true },
            avgQAAdmin: { positiveDecimal },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        ReportingAndQAValues() {
            if (this.ReportingAndQAValues === null) {
                this.reportingDashboard = null;
                this.adhocReporting = null;
                this.APIsAvailable = null;
                this.internalQAProcess = null;
                this.QARubic = null;
                this.remoteMonitor = null;
                this.clientDial = null;
                this.callRecord = null;
                this.callRecordPCT = null;
                this.callMonitorProcess = null;
                this.agentFeedBack = null;
                this.avgQAAdmin = null;
                return;
            }
            try {
                this.ReportingAndQAValues?.forEach((element) => {
                    if (element.field_name === 'reporting') this.reportingDashboard = element.json_value;
                    if (element.field_name === 'reporting_customized') {
                        this.adhocReportingID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.adhocReporting = element.json_value;
                        this.adhocReportingURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'reporting_arenacx') this.APIsAvailable = element.json_value;
                    if (element.field_name === 'qa_process') {
                        this.internalQAProcessID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.internalQAProcess = element.json_value;
                        this.internalQAProcessURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'qa_rubric') this.QARubic = element.json_value;
                    if (element.field_name === 'qa_remote_monitoring') this.remoteMonitor = element.json_value;
                    if (element.field_name === 'qa_remote_monitoring_clients') this.clientDial = element.json_value;
                    if (element.field_name === 'qa_call_recording') this.callRecord = element.json_value;
                    if (element.field_name === 'qa_call_recording_percent') this.callRecordPCT = element.json_value;
                    if (element.field_name === 'qa_call_monitoring_process') {
                        this.callMonitorProcessID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.callMonitorProcess = element.json_value;
                        this.callMonitorProcessURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'qa_agent_feedback') {
                        this.agentFeedBackID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.agentFeedBack = element.json_value;
                        this.agentFeedBackURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'quality_assurance_rubric') {
                        this.QARubicID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.QARubicURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'average_agents_per_qa_admin') this.avgQAAdmin = element.json_value;
                });
            } catch (err) {
                console.log('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.ReportingAndQAValues?.forEach((element) => {
                if (element.field_name === 'reporting') element.json_value = this.reportingDashboard;
                if (element.field_name === 'reporting_customized') element.json_value = this.stringCheck(this.adhocReporting);
                if (element.field_name === 'reporting_arenacx') element.json_value = this.APIsAvailable;
                if (element.field_name === 'qa_process') element.json_value = this.internalQAProcess;
                if (element.field_name === 'qa_rubric') element.json_value = this.QARubic;
                if (element.field_name === 'qa_remote_monitoring') element.json_value = this.remoteMonitor;
                if (element.field_name === 'qa_remote_monitoring_clients') element.json_value = this.clientDial;
                if (element.field_name === 'qa_call_recording') element.json_value = this.callRecord;
                if (element.field_name === 'qa_call_recording_percent') element.json_value = parseFloat(this.callRecordPCT);
                if (element.field_name === 'qa_call_monitoring_process')
                    element.json_value = this.stringCheck(this.callMonitorProcess);
                if (element.field_name === 'qa_agent_feedback') element.json_value = this.stringCheck(this.agentFeedBack);
                if (element.field_name === 'average_agents_per_qa_admin') element.json_value = parseFloat(this.avgQAAdmin);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.ReportingAndQAValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: start;
}
.p-inputgroup {
    max-width: 31rem;
}
.p-inputgroup-addon {
    background: rgb(228, 228, 228);
    font-size: large;
}
</style>
