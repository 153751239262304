import getAxios from './common/header';

export default class AccountMangementService {

    async addNewUser(payload) {
        var userResponse = await getAxios().post(`${process.env.VUE_APP_BASE_URL}account`, payload).then(res => {
            return res.data;
        }).catch(err => {
            return err.response
        });
        return userResponse
    }

}
