<template>
    <div class="file-container">
        <div v-if="docLink == null">
            <div class="upload-btn-wrapper" v-if="!isAttachment">
                <button class="btn">Upload</button>
                <input type="file" @change="chooseLogo" class="uploadButton" :accept="[fileTypeAccept]" />
            </div>

            <div class="upload-btn-wrapper" style="margin-top: 0.5rem; position: relative; left: 21rem" v-if="isAttachment">
                <h6 class="attach-file"><i class="pi pi-paperclip"></i>&nbsp; Attach file</h6>
                <input type="file" @change="chooseLogo" class="uploadButton" :accept="[fileTypeAccept]" />
            </div>
        </div>

        <span v-bind:class="!isAttachment ? 'upload-link' : 'attach-link'" v-if="docLink !== null || undefined">
            <a :href="docLink" target="_blank">{{ fileName }}</a>
            <i class="pi pi-times cross" @click="cancel"></i>
        </span>
    </div>
</template>

<script>
import axios from 'axios';
import CurrentUserService from '../../service/CurrentUserService';

const currentUser = new CurrentUserService();
const ifImage = (fileExt) => {
    const allowedExts = new Set(['jpg', 'jpeg', 'webp', 'png']);
    return allowedExts.has(fileExt.toLowerCase());
};
const ifDoc = (fileExt) => {
    const allowedExts = new Set(['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'webp', 'png']);
    return allowedExts.has(fileExt.toLowerCase());
};

export default {
    props: ['isImage', 'isAttachment', 'id', 'fieldId', 'logoUrl'],
    computed: {
        fileTypeAccept() {
            return this.isImage
                ? 'image/png, image/jpeg, image/webp, image/jpg'
                : 'image/png, image/jpeg, image/webp, image/jpg, application/pdf , application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        },
    },
    data() {
        return {
            fileName: '',
            docLink: null,
        };
    },
    watch: {
        logoUrl() {
            this.docLink = this.logoUrl;
            if (this.docLink) {
                var splitLink = this.docLink.split('/');
                this.fileName = splitLink[splitLink.length - 1];
            }
        },
        delete() {
            if (this.delete) {
                this.cancel();
            }
        },
    },
    methods: {
        chooseLogo(event) {
            currentUser.refreshCognitoJwt();
            const file = event.target.files[0];
            const nameSplit = file.name.split('.');
            const fileExtension = nameSplit[nameSplit.length - 1];
            if (file) {
                const checkExtension = this.isImage ? ifImage(fileExtension) : ifDoc(fileExtension);
                if (checkExtension) {
                    this.create_blob(file).then((blob_string) => {
                        axios
                            .post(
                                `${process.env.VUE_APP_UPLOAD_FILE}api/v1/partner/file-upload?id=${this.id}&field_id=${
                                    this.fieldId
                                }&file_name=${file.name.split('.')[0].substring(0, 70)}.${fileExtension}`,
                                blob_string,
                                {
                                    headers: {
                                        Authorization: currentUser.jwt,
                                        'Content-Type': file.type,
                                    },
                                }
                            )
                            .then((res) => {
                                this.docLink = res.data.link;
                                var splitLink = this.docLink.split('/');
                                this.fileName = splitLink[splitLink.length - 1];
                                this.$emit('saved', this.docLink, this.fileName);
                                this.$toast.add({ severity: 'success', summary: 'File uploaded successfully', life: 3000 });
                            })
                            .catch((err) => {
                                console.log('--ERROR TIME!--');
                                this.$toast.add({
                                    severity: 'error',
                                    summary: 'Error while uploading file',
                                    detail: err.data,
                                    life: 3000,
                                });
                                console.log(err);
                            });
                    });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'file type not accepted', life: 3000 });
                }
            }
        },
        create_blob(file) {
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);

            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(new Int8Array(reader.result));
                reader.onerror = (error) => reject(error);
            });
        },
        cancel() {
            this.docLink = null;
            axios
                .delete(`${process.env.VUE_APP_UPLOAD_FILE}api/v1/partner/file-upload?id=${this.id}&field_id=${this.fieldId}`, {
                    headers: {
                        Authorization: currentUser.jwt,
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        this.$toast.add({ severity: 'success', summary: res.data.message, life: 3000 });
                        this.$emit('saved', null, null);
                    }
                })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: err.data, life: 3000 });
                });
        },
    },
};
</script>

<style scoped lang="scss">
.file-container {
    width: 30rem;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn {
    border: 1.5px solid #949ba1;
    color: gray;
    background-color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1rem;
}

.upload-btn-wrapper input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer !important;
    margin-left: -77px !important;
}
.cross {
    cursor: pointer !important;
    margin-left: 0.5rem;
}
.attach-link {
    position: relative;
    left: 1.5rem;
    top: 0.5rem;
    word-break: break-all;
    float: right;
    > a {
        color: blue;
    }
}
.upload-link {
    position: relative;
    left: 1.5rem;
    top: 0.5rem;
    word-break: break-all;
    float: left;
    > a {
        color: blue;
    }
}
.attach-file {
    color: #126a70;
    margin-left: 3rem;
}
</style>
