<template>
    <div class="floating-icons" id="save-cancel-icon">
        <div style="margin-bottom: 1rem">
            <button @click="handleOnClick('save')" class="save icon" v-tooltip.top="{value:'Save', class: 'tooltip-btn'}">
                <i class="pi pi-save" />
            </button>
        </div>

        <div>
            <button @click="handleOnClick('cancel')" class="cancel icon" v-tooltip.bottom="{value:'Cancel', class: 'tooltip-btn'}">
                <i class="pi pi-times" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        handleOnClick(value) {
            this.$emit(value);
        },
        handleScroll() {
            if (window.pageYOffset > 100) {
                document.getElementById('save-cancel-icon').className = 'floating-icons-active';
            } else {
                document.getElementById('save-cancel-icon').className = 'floating-icons';
            }
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
};
</script>

<style scoped lang="scss">
#save-cancel-icon {
    position: fixed;
    top: 50%;
    right: 3%;
    z-index: 2;
}

.floating-icons {
    display: none;
}

.floating-icons-active {
    display: block;
}

.icon {
    color: #000;
    padding: 1rem;
    width: 3.5rem;
    border: 0;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    cursor: pointer;
    > .pi {
        font-size: 1.2rem;
    }
}

.save:hover {
    background: #c8eadb;
}

.cancel:hover {
    background: #fbbbb6;
}
</style>
