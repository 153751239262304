<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.culture_give_back }}</p>
            <Textarea
                class="input-text"
                v-model="v$.communities.$model"
                @input="changeHandler"
                placeholder="Enter how you give back to the communities in which you operate"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="communitiesID"
                :logoUrl="communitiesURL"
            />
            <p v-for="error of v$.communities.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.charitable_organizations }}</p>
            <Textarea
                class="input-text"
                v-model="v$.charity.$model"
                @input="changeHandler"
                placeholder="Enter charitable organizations or philintropic organizations"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="charityID"
                :logoUrl="charityURL"
            />
            <p v-for="error of v$.charity.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.company_values }}</p>
            <Textarea
                class="input-text"
                v-model="v$.companyValues.$model"
                @input="changeHandler"
                placeholder="Enter your company values"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="companyValuesID"
                :logoUrl="companyValuesURL"
            />
            <p v-for="error of v$.companyValues.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.mission_vision }}</p>
            <Textarea
                class="input-text"
                v-model="v$.missionVision.$model"
                @input="changeHandler"
                placeholder="Enter your mission/vision"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="missionVisionID"
                :logoUrl="missionVisionURL"
            />
            <p v-for="error of v$.missionVision.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.csr_plan_attached }}</p>
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="false"
                :id="partnerId"
                :fieldId="corpsocialrespPlanID"
                :logoUrl="corpsocialrespPlanURL"
            />
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ SocialRepLabel.diversity_certification_doc }}
                <Tooltip
                    description="An organization can be certified as 'diverse' if woman-owned, minority-owned, veteran-owned, etc.  See https://www.supplier.io/blog/what-is-a-supplier-diversity-certification for some examples and definitions."
                />
            </p>
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="false"
                :id="partnerId"
                :fieldId="diverseCertID"
                :logoUrl="diverseCertURL"
            />
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ SocialRepLabel.has_csr_plan }}
            </p>
            <Dropdown
                v-model="v$.docCorpsocialrespPlan.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select documented Corporate Social Responsibility Plan"
            />
            <p v-for="error of v$.docCorpsocialrespPlan.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';
import Tooltip from '../Tooltip.vue';

export default {
    components: {
        FileUpload,
        Tooltip,
    },
    props: ['saveInd', 'SocialRepValues'],
    data() {
        return {
            SocialRepLabel: answerBankLabel.SocialRep,
            docCorpsocialrespPlan: null,
            CorpsocialrespPlanID: null,
            corpsocialrespPlanURL: null,
            diverseCertID: null,
            diverseCertURL: null,
            communities: null,
            communitiesID: null,
            communitiesURL: null,
            charity: null,
            charityID: null,
            charityURL: null,
            companyValues: null,
            companyValuesID: null,
            companyValuesURL: null,
            missionVision: null,
            missionVisionID: null,
            missionVisionURL: null,
            partnerId: null,
            yesNoOptions: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            docCorpsocialrespPlan: { minLength: minLength(1) },
            communities: { $autoDirty: true },
            charity: { $autoDirty: true },
            companyValues: { $autoDirty: true },
            missionVision: { $autoDirty: true },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        SocialRepValues() {
            if (this.SocialRepValues === null) {
                this.docCorpsocialrespPlan = null;
                this.communities = null;
                this.charity = null;
                this.companyValues = null;
                this.missionVision = null;
                return;
            }
            try {
                this.SocialRepValues?.forEach((element) => {
                    if (element.field_name === 'has_csr_plan') this.docCorpsocialrespPlan = element.json_value;
                    if (element.field_name === 'csr_plan_attached') {
                        this.corpsocialrespPlan = element.json_value;
                        this.corpsocialrespPlanID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.corpsocialrespPlanURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'diversity_certification_doc') {
                        this.diverseCertID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.diverseCertURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'culture_give_back') {
                        this.communities = element.json_value;
                        this.communitiesID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.communitiesURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'charitable_organizations') {
                        this.charity = element.json_value;
                        this.charityID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.charityURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'company_values') {
                        this.companyValues = element.json_value;
                        this.companyValuesID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.companyValuesURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'mission_vision') {
                        this.missionVision = element.json_value;
                        this.missionVisionID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.missionVisionURL = element?.other?.attachments[0] || null;
                    }
                });
            } catch (err) {
                console.log('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.SocialRepValues?.forEach((element) => {
                if (element.field_name === 'has_csr_plan') element.json_value = this.docCorpsocialrespPlan;
                if (element.field_name === 'culture_give_back') element.json_value = this.stringCheck(this.communities);
                if (element.field_name === 'charitable_organizations') element.json_value = this.stringCheck(this.charity);
                if (element.field_name === 'company_values') element.json_value = this.stringCheck(this.companyValues);
                if (element.field_name === 'mission_vision') element.json_value = this.stringCheck(this.missionVision);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.SocialRepValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: start;
}
</style>
