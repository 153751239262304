import getAxios from './common/header';

export default class UserMangementService {

    base_url = process.env.VUE_APP_BASE_URL

    async getAccounts() {
        var accounts = await getAxios().get(`${this.base_url}account`).then(res => {
            return res.data;
        }).catch(err => {
            console.log("--ERROR TIME!--");
            console.log(err);
        });
        return accounts
    }

    async getRoles() {
        var roles = await getAxios().get(`${this.base_url}user-roles`).then(res => {
            return res.data;
        }).catch(err => {
            console.log("--ERROR TIME!--");
            console.log(err);
        });
        return roles
    }

    async addNewUser(payload) {
        var userResponse = await getAxios().post(`${this.base_url}user`, payload).then(res => {
            return res.data;
        }).catch(err => {
            return err.response
        });
        return userResponse
    }

}
