<template>
    <!-- need to update v-models -->
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ HRInfoLabel.minimum_team_size }}
                <Tooltip
                    description="Enter the smallest number of agents your firm will consider putting on a team.  (If no lower limit, enter '1.')"
                />
            </p>
            <InputText
                class="input-text"
                v-model="v$.smallTeam.$model"
                @input="changeHandler"
                placeholder="Enter minimum team size"
            />
            <p v-for="error of v$.smallTeam.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.average_team_size }}</p>
            <InputText
                class="input-text"
                v-model="v$.avgTeam.$model"
                @input="changeHandler"
                placeholder="Enter average team size"
            />
            <p v-for="error of v$.avgTeam.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.maximum_team_size }}</p>
            <InputText
                class="input-text"
                v-model="v$.largeTeam.$model"
                @input="changeHandler"
                placeholder="Enter maximum team size"
            />
            <p v-for="error of v$.largeTeam.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.agents_can_hire_1_week }}</p>
            <InputText
                class="input-text"
                v-model="v$.hireableAgentweek1.$model"
                @input="changeHandler"
                placeholder="Enter agents hireable in a week"
            />
            <p v-for="error of v$.hireableAgentweek1.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.agents_can_hire_2_week }}</p>
            <InputText
                class="input-text"
                v-model="v$.hireableAgentweek2.$model"
                @input="changeHandler"
                placeholder="Enter agents hireable in 2 weeks"
            />
            <p v-for="error of v$.hireableAgentweek2.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.agents_can_hire_3_week }}</p>
            <InputText
                class="input-text"
                v-model="v$.hireableAgentweek3.$model"
                @input="changeHandler"
                placeholder="Enter agents hireable in 3 weeks"
            />
            <p v-for="error of v$.hireableAgentweek3.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.agents_can_hire_4_week }}</p>
            <InputText
                class="input-text"
                v-model="v$.hireableAgentmonth.$model"
                @input="changeHandler"
                placeholder="Enter agents hireable in a month"
            />
            <p v-for="error of v$.hireableAgentmonth.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.percent_full_time_agents }}</p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.fullTimeAgentsPCT.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of full time agents"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.fullTimeAgentsPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.percent_part_time_agents }}</p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.partTimeAgentsPCT.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of part time agents"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.partTimeAgentsPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.percent_remote_agents }}</p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.remoteAgentsPCT.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of remote agents"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.remoteAgentsPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ HRInfoLabel.percent_shared_agents }}
                <Tooltip
                    description="'Shared' agents are engaged with several customers campaigns at once.  As opposed to 'dedicated' agents, who only work on a single customer campaign."
                />
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.sharedAgentsPCT.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of shared agents"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.sharedAgentsPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ HRInfoLabel.percent_dedicated_agents }}
                <Tooltip
                    description='"Dedicated" agents only work on a single customer&apos;s campaign at any one time.  As opposed to "shared" agents, who split their time between multiple simultaneous customer campaigns.'
                />
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.dedicatedAgentsPCT.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of dedicated agents"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.dedicatedAgentsPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ HRInfoLabel.percent_center_based_agents }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.officeBasedAgentsPCT.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of work from your offices agents"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.officeBasedAgentsPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ HRInfoLabel.shared_services }}</p>
            <Dropdown
                v-model="v$.sharedServices.$model"
                @change="changeHandler"
                :options="sharedServiceOption"
                optionLabel="name"
                optionValue="value"
                placeholder="Select shared services"
            />
            <p v-for="error of v$.sharedServices.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Tooltip from '../Tooltip.vue';

const positiveInt = (value) => {
    if (value && (value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};

const positiveDecimal = (value) => {
    if (value && (value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};

export default {
    name: 'AgentsInfo',
    components: {
        Tooltip,
    },
    props: ['saveInd', 'HRInfoValues'],
    data() {
        return {
            HRInfoLabel: answerBankLabel.HRInfo,
            partTimeAgentsPCT: null,
            fullTimeAgentsPCT: null,
            remoteAgentsPCT: null,
            officeBasedAgentsPCT: null,
            sharedServices: null,
            sharedAgentsPCT: null,
            dedicatedAgentsPCT: null,
            smallTeam: null,
            largeTeam: null,
            avgTeam: null,
            hireableAgentweek1: null,
            hireableAgentweek2: null,
            hireableAgentweek3: null,
            hireableAgentmonth: null,
            sharedServiceOption: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        };
    },

    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            partTimeAgentsPCT: { positiveDecimal, $autoDirty: true },
            fullTimeAgentsPCT: { positiveDecimal, $autoDirty: true },
            remoteAgentsPCT: { positiveDecimal, $autoDirty: true },
            officeBasedAgentsPCT: { positiveDecimal, $autoDirty: true },
            sharedServices: { minLength: minLength(1) },
            sharedAgentsPCT: { positiveDecimal, $autoDirty: true },
            dedicatedAgentsPCT: { positiveDecimal, $autoDirty: true },
            smallTeam: { positiveInt, $autoDirty: true },
            avgTeam: { positiveDecimal, $autoDirty: true },
            largeTeam: { positiveInt, $autoDirty: true },
            hireableAgentweek1: { positiveInt, $autoDirty: true },
            hireableAgentweek2: { positiveInt, $autoDirty: true },
            hireableAgentweek3: { positiveInt, $autoDirty: true },
            hireableAgentmonth: { positiveInt, $autoDirty: true },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        HRInfoValues() {
            if (this.HRInfoValues === null) {
                this.partTimeAgentsPCT = null;
                this.fullTimeAgentsPCT = null;
                this.remoteAgentsPCT = null;
                this.officeBasedAgentsPCT = null;
                this.sharedServices = null;
                this.sharedAgentsPCT = null;
                this.dedicatedAgentsPCT = null;
                this.smallTeam = null;
                this.avgTeam = null;
                this.largeTeam = null;
                this.hireableAgentweek1 = null;
                this.hireableAgentweek2 = null;
                this.hireableAgentweek3 = null;
                this.hireableAgentmonth = null;
                return;
            }
            try {
                this.HRInfoValues?.forEach((element) => {
                    if (element.field_name === 'percent_full_time_agents') this.fullTimeAgentsPCT = element.json_value;
                    if (element.field_name === 'percent_part_time_agents') this.partTimeAgentsPCT = element.json_value;
                    if (element.field_name === 'percent_remote_agents') this.remoteAgentsPCT = element.json_value;
                    if (element.field_name === 'percent_center_based_agents') this.officeBasedAgentsPCT = element.json_value;
                    if (element.field_name === 'shared_services') this.sharedServices = element.json_value;
                    if (element.field_name === 'percent_shared_agents') this.sharedAgentsPCT = element.json_value;
                    if (element.field_name === 'percent_dedicated_agents') this.dedicatedAgentsPCT = element.json_value;
                    if (element.field_name === 'minimum_team_size') this.smallTeam = element.json_value;
                    if (element.field_name === 'average_team_size') this.avgTeam = element.json_value;
                    if (element.field_name === 'maximum_team_size') this.largeTeam = element.json_value;
                    if (element.field_name === 'agents_can_hire_1_week') this.hireableAgentweek1 = element.json_value;
                    if (element.field_name === 'agents_can_hire_2_week') this.hireableAgentweek2 = element.json_value;
                    if (element.field_name === 'agents_can_hire_3_week') this.hireableAgentweek3 = element.json_value;
                    if (element.field_name === 'agents_can_hire_4_week') this.hireableAgentmonth = element.json_value;
                });
            } catch (err) {
                console.log('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        validationCall() {
            this.HRInfoValues?.forEach((element) => {
                if (element.field_name === 'percent_full_time_agents') element.json_value = parseFloat(this.fullTimeAgentsPCT);
                if (element.field_name === 'percent_part_time_agents') element.json_value = parseFloat(this.partTimeAgentsPCT);
                if (element.field_name === 'percent_remote_agents') element.json_value = parseFloat(this.remoteAgentsPCT);
                if (element.field_name === 'percent_center_based_agents')
                    element.json_value = parseFloat(this.officeBasedAgentsPCT);
                if (element.field_name === 'shared_services') element.json_value = this.sharedServices;
                if (element.field_name === 'percent_shared_agents') element.json_value = parseFloat(this.sharedAgentsPCT);
                if (element.field_name === 'percent_dedicated_agents') element.json_value = parseFloat(this.dedicatedAgentsPCT);
                if (element.field_name === 'minimum_team_size') element.json_value = parseInt(this.smallTeam);
                if (element.field_name === 'average_team_size') element.json_value = parseFloat(this.avgTeam);
                if (element.field_name === 'maximum_team_size') element.json_value = parseInt(this.largeTeam);
                if (element.field_name === 'agents_can_hire_1_week') element.json_value = parseInt(this.hireableAgentweek1);
                if (element.field_name === 'agents_can_hire_2_week') element.json_value = parseInt(this.hireableAgentweek2);
                if (element.field_name === 'agents_can_hire_3_week') element.json_value = parseInt(this.hireableAgentweek3);
                if (element.field_name === 'agents_can_hire_4_week') element.json_value = parseInt(this.hireableAgentmonth);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.HRInfoValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-inputtext) {
    max-width: 30rem;
}
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.p-inputgroup {
    max-width: 31rem;
}
.p-inputgroup-addon {
    background: rgb(228, 228, 228);
    font-size: large;
}
</style>
