<template>
    <Toast />
    <form @submit.prevent="submit()">
        <div class="p-d-flex p-jc-between">
            <p class="title">Account Management</p>
            <div>
                <!-- <Button label="Cancel" class="p-button-outlined button" @click="showSuccess" /> -->
                <Button label="Add" class="button btn" type="submit" />
            </div>
        </div>

        <div class="card">
            <h4>Add Account</h4>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Name</p>
                    <InputText type="text" v-model="v$.Name.$model" placeholder="name"></InputText>
                    <p v-for="error of v$.Name.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a first name' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Account code</p>
                    <InputText type="text" v-model="v$.accountCode.$model" placeholder="account code"></InputText>
                    <p v-for="error of v$.accountCode.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a last name' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Quickbooks ID</p>
                    <InputNumber type="text" v-model="v$.quickbooksID.$model" placeholder="quickbooksID"></InputNumber>
                    <p v-for="error of v$.quickbooksID.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a  quickbooksID' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Description</p>
                    <InputText type="text" v-model="v$.description.$model" placeholder="description"></InputText>
                    <p v-for="error of v$.description.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter decription' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Description</p>
                    <div class="radio-container">
                        <div class="field-radiobutton">
                            <RadioButton id="partner" name="partner" value="true" v-model="partner" />
                            <label class="radioLabel" for="city1">Partner</label>
                        </div>
                        <div class="field-radiobutton">
                            <RadioButton id="member" name="member" value="false" v-model="partner" />
                            <label class="radioLabel" for="member">Member</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import AccountMangementService from '../service/AccountManagementService';
import { required, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    data() {
        return {
            Name: '',
            accountCode: '',
            quickbooksID: '',
            description: '',
            partner: null,
        };
    },
    validations() {
        return {
            Name: { required, minLength: minLength(1) },
            accountCode: { required, minLength: minLength(1) },
            quickbooksID: { required, minLength: minLength(1) },
            description: { required, minLength: minLength(1) },
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.accountMangementService = new AccountMangementService();
        onMounted(() => {});
    },
    methods: {
        submit() {
            this.v$.$touch();

            var payload = {
                quickbooksID: this.quickbooksID,
                accountCode: this.accountCode,
                name: this.Name,
                description: this.description,
                isPartner: this.partner === 'true' ? true : false,
            };
            this.accountMangementService.addNewUser(payload).then((data) => {
                if (data.accountID !== null) {
                    this.$toast.add({ severity: 'success', summary: data, detail: '', life: 3000 });
                    (this.Name = ''), (this.accountCode = ''), (this.description = ''), (this.quickbooksID = '');
                    this.v$.$reset();
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while creating user', detail: data.data, life: 3000 });
                }
            });
        },
    },
};
</script>

<style scoped>
:deep(.btn) {
    width: 8rem !important;
}

:deep(.card) {
    margin-top: 1.5rem;
}

.subtitle {
    font-size: 1rem;
}

.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}

.radio-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 15rem;
    margin-left: 1rem;
}

.radioLabel {
    margin-left: 1rem;
}
::v-deep(.p-inputtext) {
    margin-left: 1rem;
    max-width: 30rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
</style>
