<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ OperationsLabel.functions_performed }}</p>
            <MultiSelect
                v-model="v$.functionsPerformed.$model"
                @change="changeHandler"
                :options="functionsPerformedOptions"
                optionLabel="name"
                placeholder="Select functions performed"
                :filter="true"
                display="chip"
            />
            <p v-for="error of v$.functionsPerformed.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ OperationsLabel.geographies_served }}</p>
            <MultiSelect
                v-model="v$.selectedGeoServ.$model"
                @change="changeHandler"
                :options="geoServ"
                optionLabel="name"
                placeholder="Select geographies served"
                display="chip"
                :filter="true"
            />
            <p v-for="error of v$.selectedGeoServ.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ OperationsLabel.skillset_provided }}</p>
            <MultiSelect
                v-model="v$.skillsetProvided.$model"
                @change="changeHandler"
                :options="skillsetProvidedOptions"
                optionLabel="name"
                placeholder="Select skillset provided"
                :filter="true"
                display="chip"
            />
            <p v-for="error of v$.skillsetProvided.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ OperationsLabel.channel_provided }}</p>
            <MultiSelect
                v-model="v$.channelProvided.$model"
                @change="changeHandler"
                :options="channelProvidedOptions"
                optionLabel="name"
                placeholder="Select channel provided"
                :filter="true"
                display="chip"
            />
            <p v-for="error of v$.channelProvided.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ OperationsLabel.hours_of_operation }}</p>
            <Textarea
                class="input-text"
                v-model="v$.hoursOfOperation.$model"
                @input="changeHandler"
                placeholder="Enter hours of operation"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <p v-for="error of v$.hoursOfOperation.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter the value' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ OperationsLabel.other_functions_performed }}</p>
            <Textarea
                class="input-text"
                v-model="v$.OtherfunctionsPerformed.$model"
                @input="changeHandler"
                placeholder="Enter other functions performed"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="OtherfunctionsPerformedID"
                :logoUrl="OtherfunctionsPerformedURL"
            />
            <p v-for="error of v$.OtherfunctionsPerformed.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ OperationsLabel.operations_24_7 }}
            </p>
            <Dropdown
                v-model="v$.supportHR.$model"
                @change="changeHandler"
                :options="OperationHourOption"
                optionLabel="name"
                optionValue="value"
                placeholder="Select support 24/7 operations"
            />
            <p v-for="error of v$.supportHR.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
    <div class="toolbar-wrap">
        <div>
            <p class="field-title" style="margin-left: 0.5rem">{{ OperationsLabel.language }}</p>
        </div>
        <Button label="Add New" class="mr-2 inline-block add-new-btn" @click="openNew" />
    </div>
    <div class="table-wrap">
        <DataTable
            ref="dt"
            :value="languageInfos"
            v-show="!!languageInfos?.length"
            dataKey="id"
            :paginator="true"
            :rows="5"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            @page="onPage($event, true)"
        >
            <Column :exportable="false" style="min-width: 2rem">
                <template #body="slotProps">
                    <Button
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-warning"
                        @click="editLanguageInfo(slotProps.data, slotProps.index)"
                    />
                    <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-warning"
                        @click="deleteLanguageInfo(slotProps.data, slotProps.index)"
                    />
                </template>
            </Column>
            <Column field="name" header="Languages name" style="min-width: 9rem"></Column>
            <Column field="levels" header="Languages level provided" style="min-width: 40rem"></Column>
        </DataTable>
    </div>

    <Dialog v-model:visible="languagesDialog" :style="{ width: '500px' }" header="Languages" :modal="true" class="p-fluid">
        <div class="field">
            <label style="margin-left: 0.5rem" for="address1"
                >{{ OperationsLabel.lang_serv }}
                <Tooltip
                    description="Proficiency 5 = Best (fully fluent when conversing, reading and writing).  Proficiency 1 = Worst (barely able to converse, read or write)."
                />
            </label>
            <div class="p-col-12 p-md-6" style="width: 100%; margin-left: -1rem">
                <Dropdown
                    v-model="selectedlanguages"
                    :options="languages"
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select languages served"
                />
            </div>
        </div>
        <div class="field" style="margin-top: 1rem">
            <label style="margin-left: 0.5rem" for="address1">{{ OperationsLabel.language_levels_provided }} </label>
            <div class="p-col-12 p-md-6" style="width: 100%; margin-left: -1rem">
                <Dropdown
                    v-model="selectedlangLevel"
                    :options="langLevel"
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select language levels provided"
                />
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="hideDialog" />
            <Button
                v-if="editLangInfoIndex === null"
                label="Add"
                class="p-button p-component button"
                @click="onLanguageInfoSave"
            />
            <Button v-else label="Update" class="p-button p-component button" @click="onLanguageInfoSave" />
        </template>
    </Dialog>

    <Dialog v-model:visible="deleteLangInfoDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 1.75rem; margin-right: 0.5rem" />
            <span v-if="languageInfo"
                >Are you sure you want to delete <b>{{ languageInfo.name }}</b
                >?</span
            >
        </div>
        <template #footer>
            <Button label="No" class="p-button p-component p-button-outlined button" @click="deleteLangInfoDialog = false" />
            <Button label="Yes" class="p-button p-component button" @click="confirmDeleteLangInfo" />
        </template>
    </Dialog>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import moment from 'moment';
import Tooltip from '../Tooltip.vue';
import FileUpload from '../common/FileUpload.vue';

export default {
    components: {
        Tooltip,
        FileUpload,
    },
    props: ['saveInd', 'OperationsValues'],
    data() {
        return {
            OperationsLabel: answerBankLabel.Operations,
            moment: moment,
            functionsPerformed: null,
            OtherfunctionsPerformed: null,
            OtherfunctionsPerformedURL: null,
            OtherfunctionsPerformedID: null,
            partnerId: null,
            selectedGeoServ: null,
            skillsetProvided: null,
            channelProvided: null,
            supportHR: null,
            hoursOfOperation: '',
            languagesDialog: false,
            editLangInfoIndex: null,
            selectedlanguages: null,
            selectedlangLevel: null,
            deleteLangInfoIndex: null,
            deleteLangInfoDialog: false,
            languageNames: [],
            paginationLanguageData: { columnOffset: 0 },
            languageLevels: [],
            languageInfos: [],
            languageInfo: {},
            OperationHourOption: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
            geoServ: [
                { name: 'Africa' },
                { name: 'Asia' },
                { name: 'Central America' },
                { name: 'Europe' },
                { name: 'Middle East' },
                { name: 'North America' },
                { name: 'Oceania' },
                { name: 'South America' },
            ],
            functionsPerformedOptions: [
                { name: 'Administrative: Audio File Transcription' },
                { name: 'Administrative: Data Entry' },
                { name: 'Administrative: Document Descriction' },
                { name: 'Administrative: Document Digitization' },
                { name: 'Administrative: Insurance Claims Processing' },
                { name: 'Administrative: Insurance Verification' },
                { name: 'Administrative: Legal Documentation' },
                { name: 'Administrative: Records Management' },
                { name: 'Administrative: Virtual Assistant' },
                { name: 'Customer Service: Answering Services' },
                { name: 'Customer Service: Appointment Setting' },
                { name: 'Customer Service: Content Moderation' },
                { name: 'Customer Service: Customer Success: Account Management' },
                { name: 'Customer Service: Customer Success: Customer Onboarding' },
                { name: 'Customer Service: Customer Success: Journey Mapping' },
                { name: 'Customer Service: Customer Success: Renewals Management' },
                { name: 'Customer Service: Customer Success: Retention' },
                { name: 'Customer Service: Customer Support' },
                { name: 'Customer Service: Fractional Leadership' },
                { name: 'Customer Service: Healthcare Services: Clinical Nurses' },
                { name: 'Customer Service: Healthcare Services: Patient Care' },
                { name: 'Customer Service: Healthcare Services: Remote Monitoring Support' },
                { name: 'Customer Service: Healthcare Services: Triage' },
                { name: 'Customer Service: Help Desk' },
                { name: 'Customer Service: Knowledge Base Management' },
                { name: 'Customer Service: Quality Assurance' },
                { name: 'Customer Service: Reservations and Booking' },
                { name: 'Customer Service: Surveys' },
                { name: 'Customer Service: Technical Support: Tier 1' },
                { name: 'Customer Service: Technical Support: Tier 2' },
                { name: 'Customer Service: Technical Support: Tier 3' },
                { name: 'Customer Service: Warranty Management' },
                { name: 'Finance and Accounting: AP/AR' },
                { name: 'Finance and Accounting: Bookkeeping' },
                { name: 'Finance and Accounting: Business Expense Management' },
                { name: 'Finance and Accounting: Collections' },
                { name: 'Finance and Accounting: Controller Services' },
                { name: 'Finance and Accounting: Customer Billing' },
                { name: 'Finance and Accounting: Financial Planning & Analysis' },
                { name: 'Finance and Accounting: Fractional CFO' },
                { name: 'Finance and Accounting: Loan Origination' },
                { name: 'Finance and Accounting: Payment/Transaction Processing' },
                { name: 'Finance and Accounting: Payroll Management' },
                { name: 'Finance and Accounting: Taxation' },
                { name: 'Human Resources: Affirmative Action Programs' },
                { name: 'Human Resources: Benefits Administration' },
                { name: 'Human Resources: Compensation Services' },
                { name: 'Human Resources: Compliance' },
                { name: 'Human Resources: Employee Relations' },
                { name: 'Human Resources: Executive Search' },
                { name: 'Human Resources: HR Assessments' },
                { name: 'Human Resources: Jobs Analysis/ Job Descriptions' },
                { name: 'Human Resources: Leave Administration' },
                { name: 'Human Resources: Payroll Administration' },
                { name: 'Human Resources: Performance Management' },
                { name: 'Human Resources: Recruiting Services' },
                { name: 'Human Resources: Training and Development' },
                { name: 'Lead Generation & Sales: Channel: Campaign Management' },
                { name: 'Lead Generation & Sales: Channel: Channel Framework & Blueprint Creation' },
                { name: 'Lead Generation & Sales: Channel: Channel Sales Management' },
                { name: 'Lead Generation & Sales: Channel: Channel Sales Support' },
                { name: 'Lead Generation & Sales: Channel: Partner Recruiting' },
                { name: 'Lead Generation & Sales: Closing Deals' },
                { name: 'Lead Generation & Sales: Direct Sales' },
                { name: 'Lead Generation & Sales: Fractional Sales Leadership' },
                { name: 'Lead Generation & Sales: Fundraising' },
                { name: 'Lead Generation & Sales: Inbound Lead Qualification' },
                { name: 'Lead Generation & Sales: Inbound Sales Order Processing' },
                { name: 'Lead Generation & Sales: Inbound Sales Support' },
                { name: 'Lead Generation & Sales: Inbound Upselling' },
                { name: 'Lead Generation & Sales: Lead Nurturing' },
                { name: 'Lead Generation & Sales: Messaging and Copywriting' },
                { name: 'Lead Generation & Sales: Online and Offline Database Generation' },
                { name: 'Lead Generation & Sales: Outbound Appointment Setting' },
                { name: 'Lead Generation & Sales: Outbound Prospecting' },
                { name: 'Lead Generation & Sales: Outbound Qualification' },
                { name: 'Lead Generation & Sales: Sales Order Processing' },
                { name: 'Lead Generation & Sales: Sales Strategy' },
                { name: 'Marketing: Branding' },
                { name: 'Marketing: Campaign Management' },
                { name: 'Marketing: Copy & Content Writing' },
                { name: 'Marketing: Customer Surveys and Reviews Management' },
                { name: 'Marketing: Digital Marketing: Programmatic Marketing' },
                { name: 'Marketing: Digital Marketing: Search Engine Marketing' },
                { name: 'Marketing: Digital Marketing: Search Engine Optimization' },
                { name: 'Marketing: Digital Marketing: Social Media' },
                { name: 'Marketing: Digital Marketing: Website Conversion' },
                { name: 'Marketing: Events / Tradeshows' },
                { name: 'Marketing: Fractional Leadership' },
                { name: 'Marketing: Graphic Design' },
                { name: 'Marketing: Influencer Programs' },
                { name: 'Marketing: Market Research' },
                { name: 'Marketing: Marketing Automation' },
                { name: 'Marketing: Traditional Advertising (Print, Television, Outdoor)' },
                { name: 'Marketing: Video Production' },
                { name: 'Marketing: Web Design' },
                { name: 'Procurement: Category Management' },
                { name: 'Procurement: Contract Management' },
                { name: 'Procurement: Cost Recovery and Invoice Auditing' },
                { name: 'Procurement: Source-to-Contract' },
                { name: 'Procurement: Sourcing Support' },
                { name: 'Procurement: Spend Analysis' },
                { name: 'Procurement: Strategic Sourcing' },
                { name: 'Procurement: Supply Market Intelligence' },
                { name: 'Procurement: Tail-Spend Management' },
                { name: 'Procurement: Tracking and Compliance' },
                { name: 'Professional Services: Consulting' },
                { name: 'Professional Services: Licensed Insurance Agents' },
                { name: 'Professional Services: Other' },
                { name: 'Professional Services: System Integrations' },
                { name: 'Technology Services: RPA' },
                { name: 'Technology Services: Software Development' },
                { name: 'Technology Services: Software QA Testing' },
            ],
            otherfunctionsPerformedOptions: [
                { name: 'Appointment Setting' },
                { name: 'Back Office' },
                { name: 'Collections' },
                { name: 'Case Management' },
                { name: 'Application processing and verification' },
                { name: 'AR' },
                { name: 'Customer Support' },
                { name: 'application processing' },
                { name: 'Billing' },
                { name: 'third party quality control' },
                { name: 'Bookkeeping' },
                { name: 'Technical Support' },
                { name: 'Clinical Nurses' },
                { name: 'Help Desk' },
                { name: 'Sales' },
                { name: 'social media' },
                { name: 'Customer Acquisition' },
                { name: 'chat' },
                { name: 'Consulting' },
                { name: 'Lead Generation' },
                { name: 'email' },
                { name: 'Maketing Research' },
                { name: 'Data Entry' },
                { name: 'text messaging and welcome call' },
                { name: 'text' },
                { name: 'Fundraising' },
                { name: 'HR' },
                { name: 'Direct Response' },
                { name: 'Telemarketing' },
                { name: 'Inbound Sales' },
                { name: 'Event Registration' },
                { name: 'Outbound Sales' },
                { name: 'IT' },
                { name: 'Reservations' },
                { name: 'Financial Advisor' },
                { name: 'Lead Verification' },
                { name: 'Sales Support' },
                { name: 'Fractional Leadership' },
                { name: 'Retention' },
                { name: 'Surveys' },
                { name: 'Software Development' },
                { name: 'Order Processing' },
                { name: 'Merchant Services' },
                { name: 'Software QA Testing' },
                { name: 'Social Media Management' },
                { name: 'Upselling' },
                { name: 'Loan Origination' },
                { name: 'Payment Processing' },
                { name: 'Virtual Assistant' },
                { name: 'Web Design' },
                { name: 'Payroll' },
                { name: 'Warranty Management' },
                { name: 'Supply Chain Support' },
                { name: 'SEO' },
                { name: 'Taxation' },
            ],
            skillsetProvidedOptions: [{ name: 'Non-Technical' }, { name: 'Tier 1' }, { name: 'Tier 2' }, { name: 'Tier 3' }],
            channelProvidedOptions: [
                { name: 'Chat' },
                { name: 'SMS/Text' },
                { name: 'Video' },
                { name: 'Email' },
                { name: 'Voice' },
                { name: 'Webform' },
            ],

            langLevel: [{ name: 'Level 1' }, { name: 'Level 2' }, { name: 'Level 3' }, { name: 'Level 4' }, { name: 'Level 5' }],
            languages: [
                { name: 'Afrikaans' },
                { name: 'Algerian Spoken Arabic' },
                { name: 'Amharic' },
                { name: 'Assamese' },
                { name: 'Bamanankan' },
                { name: 'Bavarian' },
                { name: 'Bengali' },
                { name: 'Bhojpuri' },
                { name: 'Burmese' },
                { name: 'Cameroonian Pidgin' },
                { name: 'Cebuano' },
                { name: 'Chhattisgarrhi' },
                { name: 'Chittagonian' },
                { name: 'Czech' },
                { name: 'Deccan' },
                { name: 'Dutch' },
                { name: 'Eastern Puniabi' },
                { name: 'Egyptian Spoken Arabic' },
                { name: 'English' },
                { name: 'Filipino' },
                { name: 'French' },
                { name: 'Gan Chinese' },
                { name: 'Greek' },
                { name: 'Gujarati' },
                { name: 'Hakka Chinese' },
                { name: 'Hausa' },
                { name: 'Hijazi Spoken Arabic' },
                { name: 'Hindi' },
                { name: 'Hungarian' },
                { name: 'Igbo' },
                { name: 'Indonesian' },
                { name: 'Iranian Persian' },
                { name: 'Italian' },
                { name: 'Japanese' },
                { name: 'Jinyu Chinese' },
                { name: 'Jula' },
                { name: 'Kazakh' },
                { name: 'Khmerr' },
                { name: 'Kinyarwanda' },
                { name: 'Korean' },
                { name: 'Magahi' },
                { name: 'Maithili' },
                { name: 'Malay' },
                { name: 'Malayalam' },
                { name: 'Mandarin Chinese' },
                { name: 'Marathi' },
                { name: 'Mesopotamian Spoken Arabic' },
                { name: 'Moroccan Spoken Arabic' },
                { name: 'Nepali' },
                { name: 'Nigerian Flufulde' },
                { name: 'Nigerian Pidgin' },
                { name: 'North Levantine Spoken Arabic' },
                { name: 'Northeastern Thai' },
                { name: 'Northern Kurdish' },
                { name: 'Northern Pashto' },
                { name: 'Northern Sotho' },
                { name: 'Northerrn Uzbek' },
                { name: 'Odia' },
                { name: 'Polish' },
                { name: 'Portuguese' },
                { name: 'Romanian' },
                { name: 'Russian' },
                { name: "Sa'idi Spoken Arabic" },
                { name: 'Sadri' },
                { name: 'Sanaani Spoken Arabic' },
                { name: 'Saraiki' },
                { name: 'Setswana' },
                { name: 'Sindhi' },
                { name: 'Sinhala' },
                { name: 'Somali' },
                { name: 'South Azerbaijani' },
                { name: 'South Levantine Spoken Arabic' },
                { name: 'Southern Min Chinese' },
                { name: 'Southern Sotho' },
                { name: 'Spanish' },
                { name: 'Standard Arabic' },
                { name: 'Standard German' },
                { name: 'Sudanese Spoken Arabic' },
                { name: 'Sunda' },
                { name: 'Swahili' },
                { name: 'Swedish' },
                { name: 'Sylheti' },
                { name: 'Tagalog' },
                { name: 'Tamil' },
                { name: 'Telugu' },
                { name: 'Thai' },
                { name: 'Tunisian Spoken Arabic' },
                { name: 'Turkish' },
                { name: 'Ukrainian' },
                { name: 'Urdu' },
                { name: 'Vietnamese' },
                { name: 'Western Punjabi' },
                { name: 'Wu Chinese' },
                { name: 'Xhosa' },
                { name: 'Xiang Chinese' },
                { name: 'Yoruba' },
                { name: 'Yue Chinese' },
                { name: 'Zulu' },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            functionsPerformed: { minLength: minLength(1) },
            OtherfunctionsPerformed: { $autoDirty: true },
            selectedGeoServ: { minLength: minLength(1) },
            skillsetProvided: { minLength: minLength(1) },
            channelProvided: { minLength: minLength(1) },
            supportHR: { minLength: minLength(1) },
            hoursOfOperation: { $autoDirty: true },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        OperationsValues() {
            if (this.OperationsValues === null) {
                this.functionsPerformed = null;
                this.OtherfunctionsPerformed = null;
                this.selectedGeoServ = null;
                this.skillsetProvided = null;
                this.channelProvided = null;
                this.supportHR = null;
                this.hoursOfOperation = null;
                return;
            }
            try {
                this.OperationsValues?.forEach((element) => {
                    if (element.field_name === 'functions_performed')
                        this.functionsPerformed = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'other_functions_performed') {
                        this.OtherfunctionsPerformed = element.json_value;
                        this.OtherfunctionsPerformedID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.OtherfunctionsPerformedURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'languages') this.languageNames = element.json_value || [];
                    if (element.field_name === 'language_levels_provided') this.languageInfos = element.json_value || [];
                    if (element.field_name === 'geographies_served')
                        this.selectedGeoServ = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'skillset_provided')
                        this.skillsetProvided = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'channel_provided')
                        this.channelProvided = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'operations_24_7') this.supportHR = element.json_value;
                    if (element.field_name === 'hours_of_operation') this.hoursOfOperation = element.json_value;
                });
            } catch (err) {
                console.log('Error', err.stack);
            }
        },
    },

    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        onLanguageInfoSave() {
            if (this.selectedlanguages != null && this.selectedlangLevel != null) {
                if (this.checkDuplicate(this.editLangInfoIndex)) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error while creating data',
                        detail: 'That language has already been selected',
                        life: 3000,
                    });
                    return;
                }
                const currentLangInfo = {
                    name: this.selectedlanguages,
                    levels: this.selectedlangLevel,
                };
                if (Object.keys(currentLangInfo).length < 1 || Object.values(currentLangInfo).some((ele) => ele === ''))
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Error while creating data',
                        detail: 'Enter valid data',
                        life: 3000,
                    });

                if (this.editLangInfoIndex === null) {
                    this.languageNames.unshift(this.selectedlanguages);

                    this.languageInfos.unshift(currentLangInfo);
                    this.changeHandler();
                } else {
                    this.languageNames.splice(this.editLangInfoIndex, 1, this.selectedlanguages);

                    this.languageInfos.splice(this.editLangInfoIndex, 1, currentLangInfo);
                    this.editLangInfoIndex = null;
                    this.changeHandler();
                }
                this.hideDialog();
                this.validationCall();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error while creating data',
                    detail: 'Enter valid data',
                    life: 3000,
                });
            }
        },
        checkDuplicate(index) {
            let checkExist;
            if (index === null) {
                checkExist = this.languageInfos.some((lang) => lang.name === this.selectedlanguages);
            } else {
                const filterLanguage = this.languageInfos.filter((lang) => lang.name !== this.languageInfos[index].name);
                checkExist = filterLanguage.some((lang) => lang.name === this.selectedlanguages);
            }
            return checkExist;
        },
        deleteLanguageInfo(langInfo, index) {
            this.languageInfo = langInfo;
            this.deleteLangInfoIndex = this.paginationLanguageData.columnOffset + index;
            this.deleteLangInfoDialog = true;
        },
        onPage(event, isLanguage = false) {
            if (isLanguage) {
                this.paginationLanguageData = {
                    page: event?.page, // current page
                    pageCount: event?.pageCount, // total page count
                    rows: event?.rows, // Number of rows to display in new page
                    columnOffset: event?.page * event?.rows,
                };
            } else {
                this.paginationHolidayData = {
                    page: event?.page, // current page
                    pageCount: event?.pageCount, // total page count
                    rows: event?.rows, // Number of rows to display in new page
                    columnOffset: event?.page * event?.rows,
                };
            }
        },
        editLanguageInfo(langInfo, index) {
            this.selectedlanguages = langInfo.name;
            this.selectedlangLevel = langInfo.levels;
            this.editLangInfoIndex = this.paginationLanguageData.columnOffset + index;
            this.languagesDialog = true;
        },
        confirmDeleteLangInfo() {
            this.languageInfos.splice(this.deleteLangInfoIndex, 1);
            this.languageNames.splice(this.deleteLangInfoIndex, 1);
            this.deleteLangInfoDialog = false;
            this.languageInfo = {};
            this.changeHandler();
            this.validationCall();
        },
        openNew() {
            this.selectedlanguages = null;
            this.selectedlangLevel = null;
            this.submitted = false;
            this.languagesDialog = true;
        },
        hideDialog() {
            this.languagesDialog = false;
            this.submitted = false;
            this.editLangInfoIndex = null;
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.OperationsValues?.forEach((element) => {
                if (element.field_name === 'functions_performed')
                    element.json_value = this.functionsPerformed?.map((ele) => ele.name);
                if (element.field_name === 'other_functions_performed')
                    element.json_value = this.stringCheck(this.OtherfunctionsPerformed);
                if (element.field_name === 'languages') {
                    element.json_value = this.languageNames;
                }
                if (element.field_name === 'language_levels_provided') {
                    element.json_value = this.languageInfos;
                }
                if (element.field_name === 'geographies_served')
                    element.json_value = this.selectedGeoServ?.map((ele) => ele.name);
                if (element.field_name === 'skillset_provided')
                    element.json_value = this.skillsetProvided?.map((ele) => ele.name);
                if (element.field_name === 'channel_provided') element.json_value = this.channelProvided?.map((ele) => ele.name);
                if (element.field_name === 'operations_24_7') element.json_value = this.supportHR;
                if (element.field_name === 'hours_of_operation') element.json_value = this.stringCheck(this.hoursOfOperation);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.OperationsValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: start;
}
</style>
