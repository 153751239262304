import CurrentUserService from '../CurrentUserService';
import SidebarMenuService from '../SidebarMenuService';
import { Auth } from 'aws-amplify';


export function getAccount() {
    const currentUser = new CurrentUserService();
    currentUser.refreshCognitoJwt();
    return currentUser.userAccountTypeId == 0 ? true : false;
}


export const getMenuBarData = async() => {
    const sidebarMenuService = new SidebarMenuService();
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const menuData = await sidebarMenuService.getMenuData(cognitoUser.username);
    return menuData;
}

export const setAccountId = (id) => {
    window.localStorage.setItem('accountId', id);
}

export const getAccountId = () => {
    return  window.localStorage.getItem('accountId');
}