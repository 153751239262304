<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ClientManagementLabel.average_client_tenure_years }}
            </p>

            <InputText
                id="withoutgrouping"
                :useGrouping="false"
                suffix=" years"
                v-model="v$.avgClientTenture.$model"
                @input="changeHandler"
                placeholder="Enter average client tenure in years"
            />
            <p v-for="error of v$.avgClientTenture.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ClientManagementLabel.average_agents_per_team_lead }}</p>

            <InputText
                class="input-text"
                v-model="v$.avgAgentsTL.$model"
                @input="changeHandler"
                placeholder="Enter average agents per team lead"
            />
            <p v-for="error of v$.avgAgentsTL.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ClientManagementLabel.lead_supervisor_process }}</p>
            <Textarea
                class="input-text"
                v-model="v$.TLProcedures.$model"
                @input="changeHandler"
                placeholder="Enter supervisor/team lead procedures"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="TLProceduresID"
                :logoUrl="TLProceduresURL"
            />
            <p v-for="error of v$.TLProcedures.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';

const positiveDecimal = (value) => {
    if (value && (value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};

export default {
    components: {
        FileUpload,
    },
    props: ['saveInd', 'ClientManagementValues'],
    data() {
        return {
            ClientManagementLabel: answerBankLabel.ClientManagement,
            avgClientTenture: null,
            avgAgentsTL: null,
            TLProcedures: null,
            TLProceduresID: null,
            TLProceduresURL: null,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            avgClientTenture: { positiveDecimal },
            avgAgentsTL: { positiveDecimal },
            TLProcedures: { $autoDirty: true },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        ClientManagementValues() {
            if (this.ClientManagementValues === null) {
                this.avgClientTenture = null;
                this.avgAgentsTL = null;
                this.TLProcedures = null;
                return;
            }
            try {
                this.ClientManagementValues?.forEach((element) => {
                    if (element.field_name === 'average_client_tenure_years') this.avgClientTenture = element.json_value;
                    if (element.field_name === 'average_agents_per_team_lead') this.avgAgentsTL = element.json_value;
                    if (element.field_name === 'lead_supervisor_process') {
                        this.TLProcedures = element.json_value;
                        this.TLProceduresID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.TLProceduresURL = element?.other?.attachments[0] || null;
                    }
                });
            } catch (err) {
                console.log('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.ClientManagementValues?.forEach((element) => {
                if (element.field_name === 'average_client_tenure_years') element.json_value = parseFloat(this.avgClientTenture);
                if (element.field_name === 'average_agents_per_team_lead') element.json_value = parseFloat(this.avgAgentsTL);
                if (element.field_name === 'lead_supervisor_process') element.json_value = this.stringCheck(this.TLProcedures);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.ClientManagementValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-inputtext) {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: start;
}
</style>
