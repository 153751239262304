import { useStorage } from "vue3-storage";
import { Auth } from 'aws-amplify';

// Refreshes the Cognito JWT token if needed, by using the RefreshToken behind the scenes
const performCognitoTokenRefresh = async () => {
    // https://stackoverflow.com/questions/53375350/how-handle-refresh-token-service-in-aws-amplify-js
    try {

        //console.log("--inside performCognitoTokenRefresh");
        const session = await Auth.currentSession();
        var jwtToken = session.getIdToken().getJwtToken();
        //console.log(`performCognitoTokenRefresh:jwtToken = ${jwtToken}`);
        const currentUser = new CurrentUserService();
        currentUser.jwt = jwtToken;
        console.log("JWT refreshed")
        //console.log(`currentUser.jwt = ${currentUser.jwt}`);

    } catch (error) {
        console.log("ERROR in CurrentUserService.performCognitoTokenRefresh()");
        console.log(error);
    }

};

 
export default class CurrentUserService  {

    constructor() {

    }

    _activeMember = null;
    _user = null;

    get activeMember() {
        return this._activeMember;
    }    
    set activeMember(value) {
        this._activeMember = value;
    }

    get jwt() {
        const storage = useStorage();
        let jwt = storage.getStorageSync("jwt");
        return jwt;
    }
    set jwt(value) {
        const storage = useStorage();
        storage.setStorageSync("jwt", value);
    }

    get userAccountTypeId() {
        return this.marketplaceUser?.account.accountTypeId;
    }

    get userAccountTypeName() {
        let accountTypeId = this.userAccountTypeId;
        var result = "(unknown)";

        switch(accountTypeId) {
            case 0:
                result = "ArenaCX";
                break;
            case 1:
                result = "Member";
                break;
            case 2:
                result = "Partner";
                break;
            default:
                result = "(unknown)";
                break;
        }

        return result;
    }

    get userIsArenaCX() {
        return (this.marketplaceUser?.account.accountTypeId == 0);
    }


    get refreshToken() {
        const storage = useStorage();
        let value = storage.getStorageSync("refreshToken");
        return value;
    }
    set refreshToken(value) {
        const storage = useStorage();
        storage.setStorageSync("refreshToken", value);
    }


    get marketplaceUser() {
        const storage = useStorage();
        let value = storage.getStorageSync("marketplaceUser");
        return value;
    }
    set marketplaceUser(value) {
        const storage = useStorage();
        storage.setStorageSync("marketplaceUser", value);
    }



    get authenticatedUser() {
        return Auth.currentAuthenticatedUser();
    } 

    async refreshCognitoJwt() {
        //console.log("--inside refreshCognito");
        await performCognitoTokenRefresh();
        //return jwt;
    }

}
