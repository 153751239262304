<template>
    <div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.number_active_agents }}
                </p>
                <InputText
                    class="input-text"
                    v-model="v$.activeAgents.$model"
                    @input="changeHandler"
                    placeholder="Enter number of active agents"
                />
                <p v-for="error of v$.activeAgents.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">{{ FirmographicsLabel.number_sites }}</p>
                <InputText
                    class="input-text"
                    v-model="v$.noOfSites.$model"
                    @input="changeHandler"
                    placeholder="Enter number of sites"
                />
                <p v-for="error of v$.noOfSites.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.number_countries }}
                </p>
                <InputText
                    class="input-text"
                    v-model="v$.noOfcountries.$model"
                    @input="changeHandler"
                    placeholder="Enter number of countries with operations"
                />
                <p v-for="error of v$.noOfcountries.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.agent_tenure }}
                </p>
                <InputText
                    class="input-text"
                    v-model="v$.AvgTentureactiveAgents.$model"
                    @input="changeHandler"
                    placeholder="Enter average tenure of active agents"
                />
                <p v-for="error of v$.AvgTentureactiveAgents.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value' }}
                </p>
            </div>

            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.business_model }}
                </p>
                <Dropdown
                    v-model="v$.businessModel.$model"
                    @change="changeHandler"
                    :options="businessModelOption"
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select business model"
                />
                <p v-for="error of v$.businessModel.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">{{ FirmographicsLabel.geo_type }}</p>
                <MultiSelect
                    v-model="v$.selectedGeoType.$model"
                    @change="changeHandler"
                    :options="geoType"
                    optionLabel="name"
                    placeholder="Select geographical type"
                    display="chip"
                    :filter="true"
                />
                <p v-for="error of v$.selectedGeoType.$errors" :key="error.$uid" class="error-msg">
                    {{ 'At least select one' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.industries_verticals }}
                </p>
                <MultiSelect
                    v-model="industriesVerticals"
                    @change="changeHandler"
                    :options="industriesVerticalsOptions"
                    optionLabel="name"
                    placeholder="Select industry verticals"
                    :filter="true"
                    display="chip"
                />
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.industries_verticals_all }}
                </p>
                <MultiSelect
                    v-model="selectedIndustriesVerticals"
                    @change="changeHandler"
                    :options="industriesVerticalsOptions"
                    optionLabel="name"
                    placeholder="Select all industry verticals"
                    :filter="true"
                    display="chip"
                />
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.other_industries_verticals }}
                </p>

                <Textarea
                    class="input-text"
                    type="text"
                    v-model="indusVerticalsList"
                    @input="changeHandler"
                    placeholder="Enter other industry verticals"
                    rows="5"
                    cols="30"
                    :autoResize="true"
                />
                <FileUpload
                    @saved="onSaved"
                    :isImage="false"
                    :isAttachment="true"
                    :id="partnerId"
                    :fieldId="indusVerticalsListID"
                    :logoUrl="indusVerticalsListURL"
                />
            </div>
        </div>
    </div>
</template>
<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';

const positiveInt = (value) => {
    if (value && (value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};

export default {
    name: 'Firmographics',
    components: {
        FileUpload,
    },
    props: ['saveInd', 'FirmographicsValues'],
    data() {
        return {
            FirmographicsLabel: answerBankLabel.FirmographicsLabel,
            activeAgents: null,
            AvgTentureactiveAgents: null,
            noOfSites: null,
            noOfcountries: null,
            businessModel: null,
            selectedGeoType: null,
            selectedIndustriesVerticals: null,
            industriesVerticals: null,
            indusVerticalsList: null,
            indusVerticalsListID: null,
            indusVerticalsListURL: null,
            partnerId: null,
            geoType: [{ name: 'Gig-Virtual' }, { name: 'Global' }, { name: 'Nearshore' }, { name: 'Offshore' }],
            industriesVerticalsOptions: [
                { name: 'Communication Services: Broadband' },
                { name: 'Communication Services: Cable' },
                { name: 'Communication Services: Communications' },
                { name: 'Communication Services: Entertainment and Gaming' },
                { name: 'Communication Services: ISP' },
                { name: 'Communication Services: Media/Publishing' },
                { name: 'Communication Services: Telecom' },
                { name: 'Communication Services: Wireless' },
                { name: 'Communication Services: Wireline' },
                { name: 'Communication Services: Other' },
                { name: 'Consumer Discretionary: Automotive' },
                { name: 'Consumer Discretionary: Car Rental' },
                { name: 'Consumer Discretionary: Clothing/Fashion' },
                { name: 'Consumer Discretionary: Consumer Services' },
                { name: 'Consumer Discretionary: Durable Goods' },
                { name: 'Consumer Discretionary: Ecommerce' },
                { name: 'Consumer Discretionary: Fitness and Wellness' },
                { name: 'Consumer Discretionary: Hospitality' },
                { name: 'Consumer Discretionary: Legal' },
                { name: 'Consumer Discretionary: Online Travel Agencies' },
                { name: 'Consumer Discretionary: Retail' },
                { name: 'Consumer Discretionary: Retail & Products' },
                { name: 'Consumer Discretionary: Road Assistance' },
                { name: 'Consumer Discretionary: Travel & Transportation' },
                { name: 'Consumer Discretionary: Other' },
                { name: 'Consumer Staples: Consumer Packaged Goods' },
                { name: 'Consumer Staples: Food and Beverage' },
                { name: 'Consumer Staples: Other' },
                { name: 'Energy: Energy & Resources' },
                { name: 'Energy: Mining' },
                { name: 'Energy: Oil & Gas' },
                { name: 'Energy: Other' },
                { name: 'Financials: Banking' },
                { name: 'Financials: Financial Services' },
                { name: 'Financials: Insurance (non Healthcare)' },
                { name: 'Financials: Investment' },
                { name: 'Financials: Mortgage Services' },
                { name: 'Financials: Other' },
                { name: 'Health Care: Healthcare' },
                { name: 'Health Care: Medical Equipment & Device' },
                { name: 'Health Care: Payers/Insurance' },
                { name: 'Health Care: Pharmaceutical' },
                { name: 'Health Care: Providers' },
                { name: 'Health Care: Other' },
                { name: 'Industrials: Advertising and PR' },
                { name: 'Industrials: Airline' },
                { name: 'Industrials: Business Services' },
                { name: 'Industrials: Digital Marketing' },
                { name: 'Industrials: Human Resources' },
                { name: 'Industrials: Manufacturing/Logistics' },
                { name: 'Industrials: Professional Certification' },
                { name: 'Industrials: Professional Services' },
                { name: 'Industrials: Surveys' },
                { name: 'Industrials: Transportation' },
                { name: 'Industrials: Other' },
                { name: 'Information Technology: Hardware' },
                { name: 'Information Technology: Internet of Things (IoT)' },
                { name: 'Information Technology: IT Services' },
                { name: 'Information Technology: Software' },
                { name: 'Information Technology: Other' },
                { name: 'Materials: Chemical' },
                { name: 'Materials: Construction/Renovation' },
                { name: 'Materials: Other' },
                { name: 'Public Sector: Education' },
                { name: 'Public Sector: Federal Government' },
                { name: 'Public Sector: Non-Profit' },
                { name: 'Public Sector: Political Campaigns/Surveys' },
                { name: 'Public Sector: Public Service' },
                { name: 'Public Sector: State & Local Government' },
                { name: 'Public Sector: Other' },
                { name: 'Real Estate: Real Estate' },
                { name: 'Real Estate: Other' },
                { name: 'Utilities: Renewable Energy' },
                { name: 'Utilities: Utilities' },
                { name: 'Utilities: Other' },
            ],
            businessModelOption: [{ name: 'Center Based' }, { name: 'Remote' }, { name: 'Both' }],
            fileName: null,
            deleteFile: false,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            activeAgents: { positiveInt, $autoDirty: true },
            AvgTentureactiveAgents: { positiveInt, $autoDirty: true },
            noOfSites: { positiveInt, $autoDirty: true },
            noOfcountries: { positiveInt, $autoDirty: true },
            selectedIndustriesVerticals: { minLength: minLength(1) },
            industriesVerticals: { minLength: minLength(1) },
            indusVerticalsList: { $autoDirty: true },
            businessModel: { minLength: minLength(1) },
            selectedGeoType: { minLength: minLength(1) },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        FirmographicsValues() {
            if (this.FirmographicsValues === null) {
                this.activeAgents = null;
                this.AvgTentureactiveAgents = null;
                this.noOfSites = null;
                this.noOfcountries = null;
                this.selectedIndustriesVerticals = null;
                this.industriesVerticals = null;
                this.indusVerticalsList = null;
                this.businessModel = null;
                this.selectedGeoType = null;
                return;
            }
            try {
                this.FirmographicsValues?.forEach((element) => {
                    if (element.field_name === 'number_active_agents') this.activeAgents = element.json_value;
                    if (element.field_name === 'agent_tenure') this.AvgTentureactiveAgents = element.json_value;
                    if (element.field_name === 'number_sites') this.noOfSites = element.json_value;
                    if (element.field_name === 'number_countries') this.noOfcountries = element.json_value;
                    if (element.field_name === 'business_model') this.businessModel = element.json_value;
                    if (element.field_name === 'geo_type')
                        this.selectedGeoType = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'industries_verticals')
                        this.industriesVerticals = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'industries_verticals_all')
                        this.selectedIndustriesVerticals = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'other_industries_verticals') {
                        this.indusVerticalsListID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.indusVerticalsList = element.json_value;
                        this.indusVerticalsListURL = element?.other?.attachments[0] || null;
                        if (this.indusVerticalsListURL) {
                            var splitLink = this.indusVerticalsListURL?.split('/');
                            this.fileName = splitLink[splitLink.length - 1];
                        }
                    }
                });
            } catch (err) {
                console.log('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.FirmographicsValues?.forEach((element) => {
                if (element.field_name == 'number_active_agents') element.json_value = parseInt(this.activeAgents);
                if (element.field_name == 'agent_tenure') element.json_value = parseInt(this.AvgTentureactiveAgents);
                if (element.field_name == 'number_sites') element.json_value = parseInt(this.noOfSites);
                if (element.field_name == 'number_countries') element.json_value = parseInt(this.noOfcountries);
                if (element.field_name == 'business_model') element.json_value = this.businessModel;
                if (element.field_name == 'geo_type') element.json_value = this.selectedGeoType?.map((ele) => ele.name);
                if (element.field_name == 'industries_verticals')
                    element.json_value = this.industriesVerticals?.map((ele) => ele.name);
                if (element.field_name == 'industries_verticals_all')
                    element.json_value = this.selectedIndustriesVerticals?.map((ele) => ele.name);
                if (element.field_name == 'other_industries_verticals')
                    element.json_value = this.stringCheck(this.indusVerticalsList);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.FirmographicsValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: start;
}
.link {
    position: relative;
    left: 1.5rem;
    top: 0.5rem;
    cursor: pointer;
}

.link a {
    color: blue;
}

.attach-file {
    color: #126a70;
    margin-left: 3rem;
}
</style>
