import getAxios from './header';

export async function getApi(path) {
    try {
        var response = await getAxios().get(`${process.env.VUE_APP_BASE_URL + path}`)
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function getByIdApi(path, params) {
    try {
        var response = await getAxios().get(`${process.env.VUE_APP_BASE_URL + path}`, { params })
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function postApi(path, payload) {
    console.log("payload", payload)
    try {
        var response = await getAxios().post(`${process.env.VUE_APP_BASE_URL + path}`, payload)
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function putApi(path, payload) {
    try {
        var response = await getAxios().post(`${process.env.VUE_APP_BASE_URL + path}`, payload)
        return response.data;
    } catch (err) {
        return err.response;
    }
}