<template>
    <div>
        <div class="p-d-flex p-jc-between">
            <p class="profile-title">Answer Bank</p>
            <div v-show="rootDirtyInd">
                <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="cancelAnswerBank" />
                <Button label="Save" class="p-button p-component button" @click="saveAnswerBank" />
                <FloatingSaveCancel @save="saveAnswerBank" @cancel="cancelAnswerBank" />
            </div>
        </div>

        <Accordion :multiple="true" :activeIndex="activeTab">
            <AccordionTab header="Company Firmographics">
                <Firmographics
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :FirmographicsValues="FirmographicsValues"
                    :saveInd="saveInd"
                    @submit="FirmographicsValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="HR Information">
                <HRInfo
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :HRInfoValues="HRInfoValues"
                    :saveInd="saveInd"
                    @submit="HRInfoValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Operations">
                <Operations
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :OperationsValues="OperationsValues"
                    :saveInd="saveInd"
                    @submit="OperationsValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Systems and Certifications">
                <SystemAndCert
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :SystemAndCertValues="SystemAndCertValues"
                    :saveInd="saveInd"
                    @submit="SystemAndCertValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Pricing Models and Guidance">
                <PricingModelsAndGuide
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :PricingModelsValues="PricingModelsValues"
                    :saveInd="saveInd"
                    @submit="PricingModelsValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Industries and Verticals">
                <IndustriesVerticals
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :IndustriesVerticalsValues="IndustriesVerticalsValues"
                    :saveInd="saveInd"
                    @submit="IndustriesVerticalsValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Staff Management">
                <StaffManagement
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :StaffManagementValues="StaffManagementValues"
                    :saveInd="saveInd"
                    @submit="StaffManagementValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Functions Performed">
                <FunctionsPerformed
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :FunctionsPerformedValues="FunctionsPerformedValues"
                    :saveInd="saveInd"
                    @submit="FunctionsPerformedValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Reporting and Quality Management">
                <ReportingAndQA
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :ReportingAndQAValues="ReportingAndQAValues"
                    :saveInd="saveInd"
                    @submit="ReportingAndQAValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Workflow Management">
                <WFManagement
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :WFManagementValues="WFManagementValues"
                    :saveInd="saveInd"
                    @submit="WFManagementValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Work Volume">
                <WorkVolume
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :WorkVolumeValues="WorkVolumeValues"
                    :saveInd="saveInd"
                    @submit="WorkVolumeValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Client Management">
                <ClientManagement
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :ClientManagementValues="ClientManagementValues"
                    :saveInd="saveInd"
                    @submit="ClientManagementValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Training and Improvement">
                <TraningAndImprov
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :TraningAndImprovValues="TraningAndImprovValues"
                    :saveInd="saveInd"
                    @submit="TraningAndImprovValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Resiliency">
                <Resiliency
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :ResiliencyValues="ResiliencyValues"
                    :saveInd="saveInd"
                    @submit="ResiliencyValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Social Responsibility (Impact Sourcing)">
                <SocialRep
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :SocialRepValues="SocialRepValues"
                    :saveInd="saveInd"
                    @submit="SocialRepValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Systems">
                <Systems
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :SystemsValues="SystemsValues"
                    :saveInd="saveInd"
                    @submit="SystemsValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Account Management">
                <AccManagement
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :AccManagementValues="AccManagementValues"
                    :saveInd="saveInd"
                    @submit="AccManagementValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
            <AccordionTab header="Credit score details" v-if="isAdmin">
                <CreditScore
                    @isValid="rootDirtyInd = $event"
                    :rootDirty="rootDirty"
                    :creditScoreValues="creditScoreValues"
                    :saveInd="saveInd"
                    @submit="creditScoreValidation"
                    @dirtyCall="locationDirty"
                />
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
import Firmographics from '../components/answerBank/Firmographics.vue';
import HRInfo from '../components/answerBank/HRInfo.vue';
import Operations from '../components/answerBank/Operation.vue';
import SystemAndCert from '../components/answerBank/SystemAndCert.vue';
import PricingModelsAndGuide from '../components/answerBank/PricingModelsAndGuide.vue';
import IndustriesVerticals from '../components/answerBank/IndustriesVerticals.vue';
import StaffManagement from '../components/answerBank/StaffManage.vue';
import FunctionsPerformed from '../components/answerBank/FunctionsPerformed.vue';
import ReportingAndQA from '../components/answerBank/ReportingAndQA.vue';
import WFManagement from '../components/answerBank/WFManagement.vue';
import WorkVolume from '../components/answerBank/WorkVolume.vue';
import ClientManagement from '../components/answerBank/ClientManagement.vue';
import TraningAndImprov from '../components/answerBank/TraningAndImprov.vue';
import Resiliency from '../components/answerBank/Resiliency.vue';
import SocialRep from '../components/answerBank/SocialRep.vue';
import Systems from '../components/answerBank/Systems.vue';
import AccManagement from '../components/answerBank/AccManagement.vue';
import CreditScore from '../components/answerBank/CreditScore.vue';
import FloatingSaveCancel from '../components/common/FloatingSaveCancel.vue';

import CurrentUserService from '../service/CurrentUserService';
import AnswerBankService from '../service/AnswerBankService';
import { getAccount, getAccountId } from '../service/common';

import { onMounted } from '@vue/runtime-core';
export default {
    components: {
        Firmographics,
        HRInfo,
        Operations,
        SystemAndCert,
        PricingModelsAndGuide,
        IndustriesVerticals,
        StaffManagement,
        FunctionsPerformed,
        ReportingAndQA,
        WFManagement,
        WorkVolume,
        ClientManagement,
        TraningAndImprov,
        Resiliency,
        SocialRep,
        Systems,
        AccManagement,
        CreditScore,
        FloatingSaveCancel,
    },
    name: 'AnswerBank',
    data() {
        return {
            fieldErrors: [],
            activeTab: [0],
            saveInd: false,
            answerBankData: [],
            FirmographicsValues: null,
            HRInfoValues: null,
            OperationsValues: null,
            SystemAndCertValues: null,
            PricingModelsValues: null,
            IndustriesVerticalsValues: null,
            ClientManagementValues: null,
            FunctionsPerformedValues: null,
            AccManagementValues: null,
            ReportingAndQAValues: null,
            ResiliencyValues: null,
            SocialRepValues: null,
            StaffManagementValues: null,
            SystemsValues: null,
            TraningAndImprovValues: null,
            WFManagementValues: null,
            WorkVolumeValues: null,
            creditScoreValues: null,
            count: 0,
            rootDirtyInd: false,
            partnerId: null,
            isAdmin: false,
        };
    },

    watch: {
        $route() {
            this.partnerId = getAccountId();
            this.getAnswerBank();
        },
        partnerId() {
            this.getAnswerBank();
        },
    },

    methods: {
        FirmographicsValidation(silentErrors, FirmographicsValuesData) {
            this.fieldErrors[0] = silentErrors;
            this.FirmographicsValues = FirmographicsValuesData;
        },
        HRInfoValidation(silentErrors, HRInfoValuesData) {
            this.fieldErrors[1] = silentErrors;
            this.HRInfoValues = HRInfoValuesData;
        },
        OperationsValidation(silentErrors, OperationsValuesData) {
            this.fieldErrors[2] = silentErrors;
            this.OperationsValues = OperationsValuesData;
        },
        SystemAndCertValidation(silentErrors, SystemAndCertValuesData) {
            this.fieldErrors[4] = silentErrors;
            this.SystemAndCertValues = SystemAndCertValuesData;
        },
        PricingModelsValidation(silentErrors, PricingModelsValuesData) {
            this.fieldErrors[5] = silentErrors;
            this.PricingModelsValues = PricingModelsValuesData;
        },
        IndustriesVerticalsValidation(silentErrors, IndustriesVerticalsValuesData) {
            this.fieldErrors[6] = silentErrors;
            this.IndustriesVerticalsValues = IndustriesVerticalsValuesData;
        },
        StaffManagementValidation(silentErrors, StaffManagementValuesData) {
            this.fieldErrors[7] = silentErrors;
            this.StaffManagementValues = StaffManagementValuesData;
        },
        FunctionsPerformedValidation(silentErrors, FunctionsPerformedValuesData) {
            this.fieldErrors[8] = silentErrors;

            this.FunctionsPerformedValues = FunctionsPerformedValuesData;
        },
        ReportingAndQAValidation(silentErrors, ReportingAndQAValuesData) {
            this.fieldErrors[9] = silentErrors;
            this.ReportingAndQAValues = ReportingAndQAValuesData;
        },
        WFManagementValidation(silentErrors, WFManagementValuesData) {
            this.fieldErrors[10] = silentErrors;
            this.WFManagementValues = WFManagementValuesData;
        },
        WorkVolumeValidation(silentErrors, WorkVolumeValuesData) {
            this.fieldErrors[11] = silentErrors;
            this.WorkVolumeValues = WorkVolumeValuesData;
        },
        ClientManagementValidation(silentErrors, ClientManagementValuesData) {
            this.fieldErrors[12] = silentErrors;
            this.ClientManagementValues = ClientManagementValuesData;
        },
        TraningAndImprovValidation(silentErrors, TraningAndImprovValuesData) {
            this.fieldErrors[13] = silentErrors;
            this.TraningAndImprovValues = TraningAndImprovValuesData;
        },
        ResiliencyValidation(silentErrors, ResiliencyValuesData) {
            this.fieldErrors[14] = silentErrors;
            this.ResiliencyValues = ResiliencyValuesData;
        },
        SocialRepValidation(silentErrors, SocialRepValuesData) {
            this.fieldErrors[15] = silentErrors;
            this.SocialRepValues = SocialRepValuesData;
        },
        SystemsValidation(silentErrors, SystemsValuesData) {
            this.fieldErrors[16] = silentErrors;
            this.SystemsValues = SystemsValuesData;
        },
        AccManagementValidation(silentErrors, AccManagementValuesData) {
            this.fieldErrors[17] = silentErrors;
            this.AccManagementValues = AccManagementValuesData;
        },
        creditScoreValidation(silentErrors, creditScoreValuesData) {
            this.fieldErrors[18] = silentErrors;
            this.creditScoreValues = creditScoreValuesData;
        },

        postAnswerBank(payload) {
            this.answerBankService.postAnswerBank(payload, this.$route.path.split('/')[2]).then((res) => {
                if (res === 'Saved Successfully') {
                    this.rootDirtyInd = false;
                    this.$toast.add({ severity: 'success', summary: res, detail: '', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while creating data', detail: res.data, life: 3000 });
                }
            });
        },
        async getAnswerBank() {
            this.rootDirtyInd = false;

            var response = await this.answerBankService.getAnswerBank(this.$route.path.split('/')[2]);
            var data = response.data;
            if (response.status === 200) {
                Object.keys(data).forEach((element) => {
                    if (element == 'Company Firmographics') {
                        this.FirmographicsValues = data[element];
                    }
                    if (element == 'HR Information') {
                        this.HRInfoValues = data[element];
                    }
                    if (element == 'Operations') {
                        this.OperationsValues = data[element];
                    }
                    if (element == 'Systems and Certifications') {
                        this.SystemAndCertValues = data[element];
                    }
                    if (element == 'Pricing Models and Guidance') {
                        this.PricingModelsValues = data[element];
                    }
                    if (element == 'Account Management') {
                        this.AccManagementValues = data[element];
                    }
                    if (element == 'Client Management') {
                        this.ClientManagementValues = data[element];
                    }
                    if (element == 'Functions Performed') {
                        this.FunctionsPerformedValues = data[element];
                    }
                    if (element == 'Industries and Verticals') {
                        this.IndustriesVerticalsValues = data[element];
                    }
                    if (element == 'Reporting and Quality Management') {
                        this.ReportingAndQAValues = data[element];
                    }
                    if (element == 'Resiliency') {
                        this.ResiliencyValues = data[element];
                    }
                    if (element == 'Social Responsibility (Impact Sourcing)') {
                        this.SocialRepValues = data[element];
                    }
                    if (element == 'Staff Management') {
                        this.StaffManagementValues = data[element];
                    }
                    if (element == 'Systems') {
                        this.SystemsValues = data[element];
                    }

                    if (element == 'Systems and Certifications') {
                        this.SystemAndCertValues = data[element];
                    }
                    if (element == 'Training and Improvement') {
                        this.TraningAndImprovValues = data[element];
                    }
                    if (element == 'Work Volume') {
                        this.WorkVolumeValues = data[element];
                    }
                    if (element == 'Workflow Management') {
                        this.WFManagementValues = data[element];
                    }
                    if (element == 'Credit score details') {
                        this.creditScoreValues = data[element];
                    }
                });
            } else {
                (this.FirmographicsValues = null),
                    (this.HRInfoValues = null),
                    (this.OperationsValues = null),
                    (this.SystemAndCertValues = null),
                    (this.PricingModelsValues = null),
                    (this.IndustriesVerticalsValues = null),
                    (this.ClientManagementValues = null),
                    (this.FunctionsPerformedValues = null),
                    (this.AccManagementValues = null),
                    (this.ReportingAndQAValues = null),
                    (this.ResiliencyValues = null),
                    (this.SocialRepValues = null),
                    (this.StaffManagementValues = null),
                    (this.SystemsValues = null),
                    (this.TraningAndImprovValues = null),
                    (this.WFManagementValues = null),
                    (this.WorkVolumeValues = null),
                    (this.creditScoreValues = null);
            }
        },
        cancelAnswerBank() {
            window.location.reload();
            this.getAnswerBank();
        },
        saveAnswerBank() {
            this.saveInd = true;
            const fileUploadFields = new Set([
                'observed_holidays',
                'quality_assurance_rubric',
                'projmgmt_template',
                'diversity_certification_doc',
                'bcp_document',
                'csr_plan_attached',
            ]);
            const postAnswerBankData = this.answerBankData
                .concat(
                    this.FirmographicsValues,
                    this.HRInfoValues,
                    this.OperationsValues,
                    this.SystemAndCertValues,
                    this.PricingModelsValues,
                    this.IndustriesVerticalsValues,
                    this.ClientManagementValues,
                    this.FunctionsPerformedValues,
                    this.AccManagementValues,
                    this.ReportingAndQAValues,
                    this.ResiliencyValues,
                    this.SocialRepValues,
                    this.StaffManagementValues,
                    this.SystemsValues,
                    this.TraningAndImprovValues,
                    this.WFManagementValues,
                    this.WorkVolumeValues
                )
                .flat(1);
            const postAnswerBankDataAdmin = this.answerBankData.concat(this.creditScoreValues).flat(1);
            let payload = this.isAdmin ? [...postAnswerBankData, ...postAnswerBankDataAdmin] : postAnswerBankData;
            payload = payload.filter((el) => !fileUploadFields.has(el.field_name));
            const callSave = this.fieldErrors.every((ele) => ele === 0);
            callSave
                ? this.postAnswerBank(payload, this.$route.path.split('/')[2])
                : this.$toast.add({
                      severity: 'error',
                      summary: 'Error while creating data',
                      detail: 'Missing Information',
                      life: 3000,
                  });
            this.answerBankData = [];
        },
    },

    created() {
        this.userService = new CurrentUserService();
        this.answerBankService = new AnswerBankService();
        onMounted(() => {
            this.userIsACX = this.userService.marketplaceUser.userRole.roleKey.startsWith('acx:');
            this.getAnswerBank();
            this.isAdmin = getAccount();
        });
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-accordion-header) {
    font-size: large;
}

::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link),
::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link):hover {
    background-color: rgb(228, 228, 228);
    font-weight: 500;
    margin-top: 1rem;
}

::v-deep(.p-button) {
    margin: 0 0 1.25rem 1.25rem;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

.profile-title {
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
}
</style>
