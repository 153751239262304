<template>
    <Toast />
    <form @submit.prevent="submit()">
        <div class="p-d-flex p-jc-between">
            <p class="title">User Management <span class="subtitle">(Add User in Production Environment)</span></p>
            <div>
                <!-- <Button label="Cancel" class="p-button-outlined button" @click="showSuccess" /> -->
                <Button label="Add" class="button btn" type="submit" />
            </div>
        </div>

        <div class="card">
            <h4>User Profile</h4>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-6">
                    <p class="field-title">First name</p>
                    <InputText class="input-text" type="text" v-model="v$.firstName.$model" placeholder="first name"></InputText>
                    <p v-for="error of v$.firstName.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a first name' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Last name</p>
                    <InputText class="input-text" type="text" v-model="v$.lastName.$model" placeholder="last name"></InputText>
                    <p v-for="error of v$.lastName.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a last name' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Email</p>
                    <InputText class="input-text" type="text" v-model="v$.email.$model" placeholder="email"></InputText>
                    <p v-for="error of v$.email.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a valid email' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Password</p>
                    <InputText class="input-text" type="text" v-model="v$.password.$model" placeholder="password"></InputText>
                    <p v-for="error of v$.password.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please must contain 1 lowercase, 1 uppercase and 1 number with 10 chars length' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Search account</p>
                    <AutoComplete class="input-text" placeholder="search account name" v-model="v$.accountId.$model" :suggestions="filteredData" @complete="searchCountry($event)" :dropdown="false" field="name" />
                    <p v-for="error of v$.accountId.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please select an account name' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Select role</p>
                    <Dropdown class="input-text" v-model="v$.userId.$model" :options="rolesData" optionLabel="name" optionValue="user_role_id" placeholder="user role" />
                    <p v-for="error of v$.userId.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please select a role' }}
                    </p>
                </div>
            </div>
        </div>
    </form>
</template>


<script>
import { onMounted } from '@vue/runtime-core';
import UserManagementService from '../service/Usermanagement';
import { required, minLength, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            filteredData: null,
            userId: null,
            accountId: null,
            accounsData: null,
            rolesData: [],
        };
    },
    validations() {
        return {
            firstName: { required, minLength: minLength(1) },
            lastName: { required, minLength: minLength(1) },
            email: { required, minLength: minLength(1), email },
            password: { required, minLength: minLength(10) },
            userId: { required },
            accountId: { required },
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.userManagementService = new UserManagementService();
        onMounted(() => {
            this.userManagementService.getAccounts().then((data) => {
                this.accounsData = data;
            });
            this.userManagementService.getRoles().then((data) => {
                this.rolesData = data;
            });
        });
    },
    methods: {
        submit() {
            this.v$.$touch();
            var payload = {
                email: this.email,
                account_id: this.accountId.account_id,
                user_role_id: this.userId,
                last_name: this.lastName,
                first_name: this.firstName,
                password: this.password,
            };
            this.userManagementService.addNewUser(payload).then((data) => {
                if (data == 'User Created Successfully') {
                    this.$toast.add({ severity: 'success', summary: data, detail: '', life: 3000 });
                    (this.firstName = ''), (this.lastName = ''), (this.password = ''), (this.email = ''), (this.userId = null), (this.accountId = null);
                    this.v$.$reset();
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while creating user', detail: data.data, life: 3000 });

                }
            });
        },
        searchCountry(event) {
            if (!event.query.trim().length) {
                this.filteredData = [...this.accounsData];
            } else {
                this.filteredData = this.accounsData.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },
    },
};
</script>

<style scoped>
:deep(.btn) {
    width: 8rem !important;
}

:deep(.card) {
    margin-top: 1.5rem;
}

.subtitle {
    font-size: 1rem;
}

.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.profile-title {
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
::v-deep(.p-dropdown) {
    max-width: 30rem;
}
</style>
