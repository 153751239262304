<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ TraningAndImprovLabel.average_agents_per_trainer }}
            </p>

            <InputText
                class="input-text"
                v-model="v$.agentsPerTrainer.$model"
                @input="changeHandler"
                placeholder="Enter average agents per trainer"
            />

            <p v-for="error of v$.agentsPerTrainer.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ TraningAndImprovLabel.new_agent_training_duration }}
            </p>

            <InputText
                class="input-text"
                v-model="v$.newAgentTrainHrs.$model"
                @input="changeHandler"
                placeholder="Enter new agent training duration"
            />
            <p v-for="error of v$.newAgentTrainHrs.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ TraningAndImprovLabel.number_of_trainers }}
            </p>

            <InputText
                class="input-text"
                v-model="v$.noDedicatedTrainers.$model"
                @input="changeHandler"
                placeholder="Enter number of dedicated trainers for your organization"
            />

            <p v-for="error of v$.noDedicatedTrainers.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ TraningAndImprovLabel.trainer_requirements }}</p>
            <Textarea
                class="input-text"
                v-model="v$.reqDedicatedtrainers.$model"
                @input="changeHandler"
                placeholder="Enter requirements needed to become a dedicated trainer"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="reqDedicatedtrainersID"
                :logoUrl="reqDedicatedtrainersURL"
            />
            <p v-for="error of v$.reqDedicatedtrainers.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ TraningAndImprovLabel.training_strategy }}</p>
            <Textarea
                class="input-text"
                v-model="v$.trainingStrategy.$model"
                @input="changeHandler"
                placeholder="Enter training strategy including all delivery methods for content facilitation"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="trainingStrategyID"
                :logoUrl="trainingStrategyURL"
            />
            <p v-for="error of v$.trainingStrategy.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ TraningAndImprovLabel.training_effectiveness }}</p>
            <Textarea
                class="input-text"
                v-model="v$.trainingEffective.$model"
                @input="changeHandler"
                placeholder="Enter your processes for measuring the effectiveness of training"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="trainingEffectiveID"
                :logoUrl="trainingEffectiveURL"
            />
            <p v-for="error of v$.trainingEffective.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ TraningAndImprovLabel.training_services }}</p>
            <Textarea
                class="input-text"
                v-model="v$.trainingServices.$model"
                @input="changeHandler"
                placeholder="Enter training services you provide to clients"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="trainingServicesID"
                :logoUrl="trainingServicesURL"
            />
            <p v-for="error of v$.trainingServices.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ TraningAndImprovLabel.training_gaps }}</p>
            <Textarea
                class="input-text"
                v-model="v$.skillsGaps.$model"
                @input="changeHandler"
                placeholder="Enter how you identify knowledge and/or skills gaps"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="skillsGapsID"
                :logoUrl="skillsGapsURL"
            />
            <p v-for="error of v$.skillsGaps.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ TraningAndImprovLabel.training_program }}</p>
            <Textarea
                class="input-text"
                v-model="v$.newAgentTraining.$model"
                @input="changeHandler"
                placeholder="Enter new agent training"
                rows="5"
                cols="30"
                :autoResize="true"
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldId="newAgentTrainingID"
                :logoUrl="newAgentTrainingURL"
            />
            <p v-for="error of v$.newAgentTraining.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';

const positiveInt = (value) => {
    if (value && (value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};

const positiveDecimal = (value) => {
    if (value && (value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};

export default {
    components: {
        FileUpload,
    },
    props: ['saveInd', 'TraningAndImprovValues'],
    data() {
        return {
            TraningAndImprovLabel: answerBankLabel.TraningAndImprov,
            agentsPerTrainer: null,
            noDedicatedTrainers: null,
            reqDedicatedtrainers: null,
            newAgentTraining: null,
            reqDedicatedtrainersID: null,
            newAgentTrainingID: null,
            reqDedicatedtrainersURL: null,
            newAgentTrainingURL: null,
            newAgentTrainHrs: null,
            trainingStrategy: null,
            trainingServices: null,
            trainingEffective: null,
            skillsGaps: null,
            trainingStrategyID: null,
            trainingServicesID: null,
            trainingEffectiveID: null,
            skillsGapsID: null,
            trainingStrategyURL: null,
            trainingServicesURL: null,
            trainingEffectiveURL: null,
            skillsGapsURL: null,
            partnerId: null,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            agentsPerTrainer: { positiveDecimal },
            noDedicatedTrainers: { positiveInt },
            reqDedicatedtrainers: { $autoDirty: true },
            newAgentTraining: { $autoDirty: true },
            newAgentTrainHrs: { $autoDirty: true },
            trainingStrategy: { $autoDirty: true },
            trainingServices: { $autoDirty: true },
            trainingEffective: { $autoDirty: true },
            skillsGaps: { $autoDirty: true },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        TraningAndImprovValues() {
            if (this.TraningAndImprovValues === null) {
                this.agentsPerTrainer = null;
                this.noDedicatedTrainers = null;
                this.reqDedicatedtrainers = null;
                this.newAgentTraining = null;
                this.newAgentTrainHrs = null;
                this.trainingStrategy = null;
                this.trainingServices = null;
                this.trainingEffective = null;
                this.skillsGaps = null;
                return;
            }
            try {
                this.TraningAndImprovValues?.forEach((element) => {
                    if (element.field_name === 'average_agents_per_trainer') this.agentsPerTrainer = element.json_value;
                    if (element.field_name === 'number_of_trainers') this.noDedicatedTrainers = element.json_value;
                    if (element.field_name === 'trainer_requirements') {
                        this.reqDedicatedtrainersID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.reqDedicatedtrainers = element.json_value;
                        this.reqDedicatedtrainersURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'training_program') {
                        this.newAgentTrainingID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.newAgentTraining = element.json_value;
                        this.newAgentTrainingURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'new_agent_training_duration') this.newAgentTrainHrs = element.json_value;
                    if (element.field_name === 'training_strategy') {
                        this.trainingStrategyID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.trainingStrategy = element.json_value;
                        this.trainingStrategyURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'training_services') {
                        this.trainingServicesID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.trainingServices = element.json_value;
                        this.trainingServicesURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'training_effectiveness') {
                        this.trainingEffectiveID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.trainingEffective = element.json_value;
                        this.trainingEffectiveURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'training_gaps') {
                        this.skillsGapsID = element.id;
                        this.partnerId = localStorage.getItem("accountId");
                        this.skillsGaps = element.json_value;
                        this.skillsGapsURL = element?.other?.attachments[0] || null;
                    }
                });
            } catch (err) {
                console.log('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.TraningAndImprovValues?.forEach((element) => {
                if (element.field_name === 'average_agents_per_trainer') element.json_value = parseFloat(this.agentsPerTrainer);
                if (element.field_name === 'number_of_trainers') element.json_value = parseInt(this.noDedicatedTrainers);
                if (element.field_name === 'trainer_requirements')
                    element.json_value = this.stringCheck(this.reqDedicatedtrainers);
                if (element.field_name === 'training_program') element.json_value = this.stringCheck(this.newAgentTraining);
                if (element.field_name === 'new_agent_training_duration')
                    element.json_value = this.stringCheck(this.newAgentTrainHrs);
                if (element.field_name === 'training_strategy') element.json_value = this.stringCheck(this.trainingStrategy);
                if (element.field_name === 'training_services') element.json_value = this.stringCheck(this.trainingServices);
                if (element.field_name === 'training_effectiveness')
                    element.json_value = this.stringCheck(this.trainingEffective);
                if (element.field_name === 'training_gaps') element.json_value = this.stringCheck(this.skillsGaps);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.TraningAndImprovValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: start;
}
</style>
