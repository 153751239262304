import { getAccountId } from './common';
import getAxios from './common/header';

export default class AnswerBankService {
    async getAnswerBank() {
        const accountId = getAccountId();
        try {
            var response = await getAxios().get(`${process.env.VUE_APP_BASE_URL_V_TWO}answer-bank?id=${accountId}`);
            console.log(response.status);
            return await response;
        } catch (err) {
            console.log(err.response);
            return err.response;
        }
    }

    async postAnswerBank(payload) {
        const accountId = getAccountId();

        var answerBankData = await getAxios()
            .post(`${process.env.VUE_APP_BASE_URL_V_TWO}answer-bank?id=${accountId}`, payload)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.log('--ERROR TIME!--');
                console.log(err);
                return err.response;
            });
        return answerBankData;
    }
}
