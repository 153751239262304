import { getAccount, getAccountId } from './common';
import { getApi, getByIdApi, postApi } from './common/apis';

export default class ProfileService {
    base_url = process.env.VUE_APP_BASE_URL;

    async getProfile() {
        var profileData = await getApi('partner-profile')
        return profileData
    }

    async getProfileById() {
        const accountId = getAccountId();
        var profileData = await getByIdApi('partner-profile', { id: accountId })
        return profileData
    }

    async updateProfile(payload) {
        const accountId = getAccountId();
        var url = getAccount() ? `partner-profile?id=${accountId}` : `partner-profile`;
        var profileData = await postApi(url, JSON.parse(JSON.stringify(payload)))
        return profileData
    }
}
