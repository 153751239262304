<template>
    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                <h4>Your Opportunities</h4>
                <h6 :style="userIsACX ? 'display:none;' : ''">
                    <i style="font-size: 2rem" class="pi pi-check-circle p-mr-2 p-p-2 white-bgcolor blue-color"></i>
                    (This page is a work in progress!  The content below is fictional but illustrates the kind of data our Partners would see as they engage in and complete new RFPs.)
                </h6>
            </div>
            <div class="card p-fluid">
                <DataTable :value="products" 
                showGridlines 
                :scrollable="true" 
                responsiveLayout="scroll"
                stripedRows 
                scrollHeight="500px" 
                :loading="loading"
                :resizableColumns="true" 
                >
                    <template #header> You have been invited to participate in these RFPs </template>
                    <Column field="id" header="ID" headerStyle="width: 115px;" sortable></Column>
                    <Column field="name" sortable>
                        <template #header>
                            Name
                            <span style="margin-left: 12px;font-size: 0.8em; font-weight:normal;"> (Click to submit/review your entry)</span>
                        </template>
                        <template #body="slotProps">
                            <div class="opportunityLink">
                                {{slotProps.data.name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="dueDate" header="Due Date" sortable></Column>
                    <Column field="status" header="Status" sortable  >
                        <template #body="slotProps">
                            <div v-if="slotProps.data.status == 0">Open</div>
                            <div v-else-if="slotProps.data.status == 1">Closed</div>
                            <div v-else-if="slotProps.data.status == 2">Over Due</div>
                        </template>
                    </Column>
                    <Column field="outcome" header="Outcome" sortable >
                        <template #body="slotProps">
                            <div v-if="slotProps.data.outcome == 0">Won</div>
                            <div v-else-if="slotProps.data.outcome == 1">Lost</div>
                            <div v-else-if="slotProps.data.outcome == 2">Lost (Overdue)</div>
                            <div v-else-if="slotProps.data.outcome == null"><i>(Pending)</i></div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import ProductService from '../service/OpportunitiesService';
import CurrentUserService from '../service/CurrentUserService';

export default {
    data() {
        return {
            products: null,
            userIsACX: false,
            currentUser: null
        }
    },
    productService: null,
    userService: null,
    created() {
        this.productService = new ProductService();
        this.userService = new CurrentUserService();
    },
    mounted() {
        this.productService.getProductsSmall().then(data => this.products = data);
        this.userIsACX = this.userService.marketplaceUser.userRole.roleKey.startsWith("acx:");
        //console.log("userIsACX", this.userIsACX);
    }
}
</script>



<style lang="scss" scoped>

.opportunityLink {
    color: darkgreen;
    text-decoration: underline;
    cursor: pointer;
}

.opportunityLink:hover {
    
}
</style>
